import * as React from 'react';

import EditPlaylistView           from '@components/Playlist/EditPlaylist/EditPlaylistView';
import { PlaylistInterface }      from '@components/Playlist/PlaylistInterface';
import { EditPlaylistErrorType }  from '@components/Playlist/EditPlaylist/EditPlaylistErrorType';

import FlashContext from '@contexts/FlashContext';
import PlaylistApi from '@api/PlaylistApi';
import {
  PLAYLIST_NAME_MIN_LENGTH,
  PLAYLIST_NAME_MAX_LENGTH,
  PLAYLIST_DESCRIPTION_MAX_LENGTH
} from '@resources/validationRules';

interface EditPlaylistProps {
  playlist: PlaylistInterface;
  onCloseEditPlaylistDialog: () => void;
}

interface EditPlaylistState {
  errors: EditPlaylistErrorType;
  name: string;
  description: string;
  saving: boolean;
}

const errorsInitialState = {
  name: '',
  description: ''
};

export default class EditPlaylist extends React.Component<EditPlaylistProps, EditPlaylistState> {
  static contextType = FlashContext;

  state = {
    name: this.props.playlist.name,
    description: this.props.playlist.description,
    errors: errorsInitialState,
    saving: false
  }

  handleOnNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.currentTarget;
    this.setState({ name: value });
  }

  handleOnDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.currentTarget;
    this.setState({ description: value });
  }

  handleOnSave = (event: React.SyntheticEvent): void => {
    event.preventDefault();

    if (this.isValid()) {
      const { id } = this.props.playlist;
      const {
        name,
        description
      } = this.state;

      this.setState({ saving: true });

      PlaylistApi
        .updatePlaylist(id, name, description)
        .then((res) => {
          this.setState({ saving: false }, () => {
            setTimeout(() => { window.location.reload(); }, 1500);
          });

          const status = ['success', res.body.status];
          this.context.flashMessage(status);
          this.props.onCloseEditPlaylistDialog();
        })
        .catch(() => {
          this.setState({ saving: false });
          const status = ['alert', 'Your delete request failed. Please try again or contact us for support.'];
          this.context.flashMessage(status);
        })
      ;
    }
  }

  isValid(): boolean {
    const {
      name,
      description
    } = this.state;

    const errors = { ...errorsInitialState };

    let isValid = true;

    if (name.length < PLAYLIST_NAME_MIN_LENGTH) {
      errors.name = `The playlist name must contain at least ${PLAYLIST_NAME_MIN_LENGTH} character(s).`;
      isValid = false;
    }

    if (name.length > PLAYLIST_NAME_MAX_LENGTH) {
      errors.name = `The playlist name cannot exceed ${PLAYLIST_NAME_MAX_LENGTH} characters.`;
      isValid = false;
    }

    if (description && description.length > PLAYLIST_DESCRIPTION_MAX_LENGTH) {
      errors.description = `The playlist description cannot exceed ${PLAYLIST_DESCRIPTION_MAX_LENGTH} characters.`;
      isValid = false;
    }

    this.setState({ errors });

    return isValid;
  }

  render(): JSX.Element {
    const {
      name,
      description,
      errors,
      saving
    } = this.state;

    const {
      onCloseEditPlaylistDialog
    } = this.props;

    return (
      <EditPlaylistView
        name={name}
        description={description}
        errors={errors}
        saving={saving}
        onChangeName={this.handleOnNameChange}
        onChangeDescription={this.handleOnDescriptionChange}
        onCloseEditPlaylistDialog={onCloseEditPlaylistDialog}
        onSave={this.handleOnSave}
      />
    );
  }
}
