import * as React from 'react';

import Box            from '@material-ui/core/Box';
import Button         from '@material-ui/core/Button';
import Fade           from '@material-ui/core/Fade';
import FormControl    from '@material-ui/core/FormControl';
import Grid           from '@material-ui/core/Grid';
import Link           from '@material-ui/core/Link';
import TextField      from '@material-ui/core/TextField';
import useMediaQuery  from '@material-ui/core/useMediaQuery';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import PasswordInput  from '@components/common/form/PasswordInput';
import withAuth       from '@components/Auth/withAuth';

import LoadingIndicator   from '@components/common/LoadingIndicator';
import DefaultTheme       from '@themes/DefaultTheme';
import FormHelperText     from '@styles/form/FormHelperText';
import PageGridItem       from '@styles/PageGridItem';
import Text               from '@styles/Text';

import { WithAuthInterface } from '@components/Auth/WithAuthInterface';

const useStyles = makeStyles(({ spacing }: DefaultTheme) => createStyles({
  form: {
    marginTop: spacing(5)
  },
  headingBox: {
    marginBottom: spacing(2)
  },
  submitBtn: {
    marginTop: spacing(2)
  }
}));

const LogIn = React.forwardRef((props: WithAuthInterface, ref: React.Ref<HTMLFormElement>): JSX.Element => {
  const classes = useStyles();
  const isMobileResolution = useMediaQuery('(max-width:767px)');

  const {
    auth,
    csrfToken,
    errors,
    formAction,
    onChange,
    onSubmit,
    loading,
    redirectPath
  } = props;

  return (
    <PageGridItem>
      <form
        action={formAction}
        className={classes.form}
        method="post"
        onSubmit={onSubmit}
        ref={ref}
        noValidate
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box className={classes.headingBox}>
              <Text type="heading">
                Log In
              </Text>
            </Box>

            <Box className={classes.headingBox}>
              <Text type="displayXs">
                New user?
                {' '}
                <Link
                  href="/users/sign_up"
                >
                  Create an account
                </Link>
                .
              </Text>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              error={errors.email.length > 0}
              fullWidth
            >
              <TextField
                error={errors.email.length > 0}
                name="user[email]"
                id="email"
                label="Email Address"
                type="email"
                onChange={onChange}
                value={auth.email}
                variant="outlined"
                required
              />

              {errors.email.length > 0 && (
                <Fade in>
                  <FormHelperText>
                    {errors.email}
                  </FormHelperText>
                </Fade>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <PasswordInput
              fieldId="password"
              fieldName="user[password]"
              fieldLabel="Password"
              fieldValue={auth.password}
              onChangeHandler={onChange}
              error={errors.password}
              required
            />

            <input type="hidden" name="authenticity_token" value={csrfToken} />
            <input type="hidden" name="redirect_path" value={redirectPath} />
          </Grid>

          {errors.other.length > 0 && (
            <Grid item xs={12}>
              <FormControl error>
                <Fade in>
                  <FormHelperText>
                    {errors.other}
                  </FormHelperText>
                </Fade>
              </FormControl>
            </Grid>
          )}

          <Grid item xs={12}>
            <Link href="/users/password/new">
              I forgot my password.
            </Link>
          </Grid>

          <Grid item xs={12}>
            <Button
              className={classes.submitBtn}
              color="primary"
              disabled={loading}
              fullWidth={isMobileResolution}
              onClick={onSubmit}
              type="submit"
              variant="contained"
              size="large"
            >
              {loading && <LoadingIndicator />}
              Log In
            </Button>
          </Grid>
        </Grid>
      </form>
    </PageGridItem>
  );
});

export default withAuth(LogIn);
