import * as React from 'react';

import { AuthInterface }      from '@components/Auth/AuthInterface';
import { AuthErrorType }      from '@components/Auth/AuthErrorType';
import authInitialState       from '@components/Auth/AuthInitialState';
import { WithAuthInterface }  from '@components/Auth/WithAuthInterface';
import {
  EMAIL_REGEX
} from '@resources/validationRules';

interface AuthState {
  auth: AuthInterface;
  errors: AuthErrorType;
  loading: boolean;
}

interface AuthProps {
  csrfToken: string;
  email?: string;
  errors: AuthErrorType;
  formAction: string;
  redirectPath?: string;
}

const errorsInitialState = {
  ...authInitialState,
  other: ''
};

export default function withAuth<T extends WithAuthInterface = WithAuthInterface>(
  WrappedComponent: React.ComponentType<WithAuthInterface>
): React.ComponentClass {
  return class Auth extends React.Component<AuthProps, AuthState> {
    private formRef = React.createRef<HTMLFormElement>();

    static defaultProps = {
      email: ''
    };

    constructor(props: AuthProps) {
      super(props);

      const {
        email,
        errors
      } = props;

      const parsedErrors = {};
      Object.entries(errors).forEach((error) => {
        const [k, v] = error;
        parsedErrors[k] = Array.isArray(v) ? v.join(', ') : v;
      });

      this.state = {
        errors: {
          ...errorsInitialState,
          ...parsedErrors
        },
        auth: {
          ...authInitialState,
          email
        },
        loading: false
      };
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { id, value } = event.currentTarget;

      this.setState((prevState: AuthState): AuthState => ({
        ...prevState,
        auth: {
          ...prevState.auth,
          [id]: value
        }
      }));
    };

    handleSubmit = (event: React.SyntheticEvent): boolean => {
      event.preventDefault();

      if (!this.isValid()) {
        return false;
      }

      if (this.formRef.current) {
        this.formRef.current.submit();
      }

      return true;
    };

    isValid(): boolean {
      const { auth }  = this.state;
      const errors    = { ...errorsInitialState };

      let isValid = true;

      if (EMAIL_REGEX.test(auth.email) === false) {
        errors.email = 'Invalid email provided.';
        isValid = false;
      }

      if (!auth.password) {
        errors.password = 'Please enter your password.';
        isValid = false;
      }

      this.setState({ errors });

      return isValid;
    }

    render(): React.ReactNode {
      const {
        auth,
        errors,
        loading
      } = this.state;

      const {
        csrfToken,
        formAction,
        redirectPath
      } = this.props;

      return (
        <WrappedComponent
          auth={auth}
          csrfToken={csrfToken}
          errors={errors}
          formAction={formAction}
          ref={this.formRef}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
          loading={loading}
          redirectPath={redirectPath}
        />
      );
    }
  };
}
