import * as React from 'react';

import Box  from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import Text         from '@styles/Text';
import DefaultTheme from '@themes/DefaultTheme';

interface NoPlaylistsMessageProps {
  publicView: boolean;
}

const useStyles = makeStyles(({ spacing }: DefaultTheme) => createStyles({
  topSpacing: {
    marginTop: spacing(3)
  }
}));

export default function NoPlaylistsMessage({ publicView = false }: NoPlaylistsMessageProps): JSX.Element {
  const classes = useStyles();

  const message = publicView ? (
    <Text type="label">
      No shared playlists found. Do you have something to share?
      {' '}
      <Link href="/users/login">
        Log in
      </Link>
      {' or '}
      <Link href="/users/sign_up">
        Sign up
      </Link>
      {' '}
      to start sharing.
    </Text>
  ) : (
    <Text type="label">
      No playlists found. Try
      {' '}
      <Link href="/integrations">
        connecting to a provider
      </Link>
      {' '}
      and synchronizing it with your account, or add a
      {' '}
      <Link href="/shared">
        shared playlist
      </Link>
      {' '}
      to your collection.
    </Text>
  );

  return (
    <Grid item xs={12}>
      <Box className={classes.topSpacing}>
        {message}
      </Box>
    </Grid>
  );
}
