import * as React from 'react';

import Avatar         from '@material-ui/core/Avatar';
import Card           from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader     from '@material-ui/core/CardHeader';
import CardMedia      from '@material-ui/core/CardMedia';
import CardContent    from '@material-ui/core/CardContent';
import Checkbox       from '@material-ui/core/Checkbox';
import IconButton     from '@material-ui/core/IconButton';
import MoreVertIcon   from '@material-ui/icons/MoreVert';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import PlaylistMenu           from '@components/Playlist/PlaylistMenu/PlaylistMenu';
import PublicPlaylistMenu     from '@components/Playlist/PlaylistMenu/PublicPlaylistMenu';
import { PlaylistInterface }  from '@components/Playlist/PlaylistInterface';
import { UserInterface }      from '@components/User/UserInterface';
import PlaylistHelper         from '@resources/helpers/PlaylistHelper';

import DefaultTheme from '@themes/DefaultTheme';
import Text         from '@styles/Text';

interface ManageHomeProps {
  inEditMode: boolean;
  isSelected: boolean;
  onEditSelectItem: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  playlist: PlaylistInterface;
  publicView?: boolean;
  userId: null | UserInterface['id'];
}

const useStyles = makeStyles(({ spacing }: DefaultTheme) => createStyles({
  root: {
    width: 'auto'
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  cardAction: {
    position: 'relative'
  },
  cardActionText: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    height: 70,
    width: '100%',
    overflow: 'hidden'
  },
  checkBox: {
    textAlign: 'right'
  },
  contentText: {
    marginTop: spacing(2)
  }
}));

export default function PlaylistCard({
  inEditMode,
  isSelected,
  onEditSelectItem,
  playlist,
  publicView = false,
  userId
}: ManageHomeProps): JSX.Element {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const classes = useStyles();

  const handleMenuClick = (event) => setMenuAnchorEl(event.currentTarget);
  const handleMenuClose = () => setMenuAnchorEl(null);
  const playlistHelper  = new PlaylistHelper(playlist);

  const {
    title,
    subHeader,
    playlistLink,
    playlistImage,
    providerName
  } = playlistHelper.parseDisplayInfo(30, publicView);

  // const avatarLetters = playlist.name.split(' ').filter(e => /\w/.test(e)).map(e => e.substr(0,1)).slice(0, 3).join('').toUpperCase();

  const playlistMenu = publicView ? (
    <PublicPlaylistMenu
      anchorEl={menuAnchorEl}
      onClose={handleMenuClose}
      playlist={playlist}
      userId={userId}
      variant="Menu"
    />
  ) : (
    <PlaylistMenu
      anchorEl={menuAnchorEl}
      onClose={handleMenuClose}
      playlist={playlist}
      variant="Menu"
    />
  );

  return (
    <>
      <Card className={classes.root}>
        <CardHeader
          avatar={(
            <>
              {inEditMode && (
                <Checkbox
                  checked={isSelected}
                  onChange={onEditSelectItem}
                  value={playlist.id}
                />
              )}
              {!inEditMode && (
                <Avatar aria-label="recipe">
                  {playlist.name.substring(0, 1)}
                </Avatar>
              )}
            </>
          )}
          action={(
            <IconButton aria-label="settings" onClick={handleMenuClick}>
              <MoreVertIcon />
            </IconButton>
          )}
          title={title}
          subheader={subHeader}
        />
        <CardActionArea className={classes.cardAction}>
          <a href={playlistLink}>
            <CardMedia
              className={classes.media}
              image={playlistImage}
              title={playlist.name}
            />
            {playlist.description && (
              <CardContent className={classes.cardActionText} title={playlist.description}>
                <Text type="label">
                  {playlist.description}
                </Text>
              </CardContent>
            )}
          </a>
        </CardActionArea>
        <CardContent>
          {publicView && (
            <Text type="regular">
              {`Shared by ${playlist.user.username}.`}
            </Text>
          )}

          <Text type="regular">
            {`Imported from ${providerName} on ${playlist.createdAt}.`}
          </Text>
          <Text type="regular">
            Last updated
            {' '}
            {playlist.lastSyncedAt}
            .
          </Text>
        </CardContent>
      </Card>

      {playlistMenu}
    </>
  );
}
