interface PlaylistSortOption {
  [name: string]: {
    label: string;
    sortBy: string;
    order: string;
  }
}

export const PlaylistSortOptions: PlaylistSortOption = {
  synced_at_asc: {
    label: 'Last Synced (newest)',
    sortBy: 'updated_at',
    order: 'desc'
  },
  synced_at_desc: {
    label: 'Last Synced (oldest)',
    sortBy: 'updated_at',
    order: 'asc'
  },
  name_asc: {
    label: 'Alphabetical (A-Z)',
    sortBy: 'name',
    order: 'asc'
  },
  name_desc: {
    label: 'Alphabetical (Z-A)',
    sortBy: 'name',
    order: 'desc'
  },
  tracks_count_asc: {
    label: 'Least Tracks',
    sortBy: 'tracks_count',
    order: 'asc'
  },
  tracks_count_desc: {
    label: 'Most Tracks',
    sortBy: 'tracks_count',
    order: 'desc'
  }
} as const;
