import * as React from 'react';

import Link from '@material-ui/core/Link';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import DefaultTheme from '@themes/DefaultTheme';
import Text         from '@styles/Text';

interface LogoProps {
  hideBetaIcon?: boolean;
  showLogoImage?: boolean;
}

const useStyles = makeStyles(({ spacing }: DefaultTheme) => createStyles({
  anchor: {
    display: 'flex',
    marginTop: '6px',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  betaWarn: {
    marginLeft: spacing(2)
  },
  logoImage: {
    marginRight: spacing(1),
    height: '35px'
  }
}));

export default function Logo({ hideBetaIcon = true, showLogoImage = false }: LogoProps): JSX.Element {
  const classes = useStyles();

  return (
    <Link
      className={classes.anchor}
      title="Playlists.cloud"
      href="/"
    >
      {showLogoImage && <img className={classes.logoImage} src="/images/logo.png" alt="" />}

      <Text component="h1" color="grey" type="displaySm" weight={500}>
        Playlists.cloud
      </Text>
      {!hideBetaIcon && (
        <Text type="regular" color="red" className={classes.betaWarn}>
          (Beta)
        </Text>
      )}
    </Link>
  );
}
