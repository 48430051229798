import * as React from 'react';

import ProviderListView           from '@components/Providers/ProviderListView';
import connectProviderComponents  from '@components/Providers/common/connectProviderComponents';
import { ProvidersInterface }     from '@components/Providers/ProvidersInterface';

import FlashContext from '@contexts/FlashContext';

import ProviderApi from '@api/ProviderApi';
import {
  defaultApiErrorMessage,
  genericApiResponse
} from '@api/BaseApi';

interface ProviderListProps {
  providers: ProvidersInterface;
  ssoState: string;
}

export default function ProviderList({
  providers,
  ssoState
}: ProviderListProps): JSX.Element {
  const flash = React.useContext(FlashContext);

  const handleImportPlaylists = (source: string): void => {
    ProviderApi
      .syncProviders(source)
      .then((res: { body: genericApiResponse }) => {
        const status = ['notice', res.body.status];
        flash.flashMessage(status);
      })
      .catch((res) => {
        const message = res.response && res.response.body ? res.response.body.error : defaultApiErrorMessage;
        const error = ['alert', message];
        flash.flashMessage(error);
      })
    ;
  };

  const providerComponents = connectProviderComponents(providers, ssoState);

  return (
    <ProviderListView
      onImportPlaylists={handleImportPlaylists}
      providers={providers}
      providerComponents={providerComponents}
    />
  );
}
