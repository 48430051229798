import * as React from 'react';

import Grid   from '@material-ui/core/Grid';
import Link   from '@material-ui/core/Link';
import Paper  from '@material-ui/core/Paper';
import LaunchIcon from '@material-ui/icons/Launch';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import Logo from '@components/Header/Logo';

import DefaultTheme from '@themes/DefaultTheme';
import Text         from '@styles/Text';

const useStyles = makeStyles(({ colors, spacing }: DefaultTheme) => createStyles({
  root: {
    flexGrow: 1,
    padding: '50px 0',
    backgroundColor: colors.black
  },
  containerGrid: {
    borderTop: `1px solid ${colors.lightBlack}`,
    paddingTop: spacing(5)
  },
  launchIcon: {
    marginLeft: spacing(1),
    fontSize: '12px'
  },
  rightPaneGridItem: {
    textAlign: 'right'
  },
  supportImage: {
    border: 0,
    height: '36px'
  }
}));

export default function FooterBar(): JSX.Element {
  const classes = useStyles();

  return (
    <Paper className={classes.root} elevation={0}>
      <Grid container className={classes.containerGrid} spacing={4}>
        <Grid item xs={12}>
          <Logo hideBetaIcon />
        </Grid>
        <Grid item xs={2}>
          <Link href="/about">
            <Text type="label">
              About
            </Text>
          </Link>

          <Link href="/help">
            <Text type="label">
              Help
            </Text>
          </Link>

          <Link href="/faq">
            <Text type="label">
              FAQ
            </Text>
          </Link>
          <Link href="https://ko-fi.com/zzzrod/posts" target="_blank">
            <Text type="label">
              Blog
            </Text>
          </Link>
        </Grid>
        <Grid item xs={2}>
          <Link href="/contact">
            <Text type="label">
              Contact
            </Text>
          </Link>

          <Link href="/disclaimer">
            <Text type="label">
              Disclaimer
            </Text>
          </Link>

          <Link href="/privacy">
            <Text type="label">
              Privacy
            </Text>
          </Link>
        </Grid>
        <Grid item xs={8} className={classes.rightPaneGridItem}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Link
                href="https://ko-fi.com/D1D4C7D8A"
                target="_blank"
              >
                <img
                  height="36"
                  className={classes.supportImage}
                  src="https://cdn.ko-fi.com/cdn/kofi5.png?v=6"
                  alt="Buy Me a Coffee at ko-fi.com"
                />
              </Link>
            </Grid>
            <Grid item xs={12}>
              <Link
                href="https://discord.gg/H7Xg9AAHsq"
                target="_blank"
              >
                <Text type="label">
                  Playlists.cloud on Discord
                  <LaunchIcon className={classes.launchIcon} />
                </Text>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
