import * as React from 'react';
import request from 'superagent';

import { FlashContextInterface }  from '@contexts/FlashContext';
import { PlaylistTrackInterface } from '@components/Playlist/PlaylistTrackInterface';
import { PlaylistInterface }      from '@components/Playlist/PlaylistInterface';
import {
  BASE_URL, defaultApiErrorMessage, genericApiResponse
} from '@api/BaseApi';

const ENDPOINT = `${BASE_URL}/shared`;

interface getSharedPlaylistsResponse {
  page: number;
  pages: number;
  playlists: Array<PlaylistInterface>;
  total: number;
}

interface getPlaylistTracksResponse {
  page: number;
  playlistTracks: Array<PlaylistTrackInterface>;
  total: number;
}

export default class SharedPlaylistApi {
  static getPlaylists(
    order:      string = 'ASC',
    page:       number = 1,
    searchTerm: string = '',
    sortBy:     string = null
  ): Promise<{ body: getSharedPlaylistsResponse }> {
    const sortOrderBy = sortBy ? `&sort_by=${sortBy}&order=${order}` : '';
    const term = searchTerm.length > 0 ? `&term=${searchTerm}` : '';

    return request
      .get(`${ENDPOINT}/?page=${page}${sortOrderBy}${term}`)
    ;
  }

  static getPlaylistTracks(
    id:         string,
    page:       number = 0,
    perPage:    number = 0,
    searchTerm: string = '',
    sortBy:     string = null,
    order:      string = 'ASC'
  ): Promise<{ body: getPlaylistTracksResponse }> {
    const itemsPerPage  = perPage > 0 ? `&per_page=${perPage}` : '';
    const sortOrderBy   = sortBy ? `&sort_by=${sortBy}&order=${order}` : '';
    const term          = searchTerm.length > 0 ? `&term=${searchTerm}` : '';

    return request
      .get(`${ENDPOINT}/${id}/tracks?page=${page}${itemsPerPage}${sortOrderBy}${term}`)
    ;
  }

  static exportPlaylists(
    ids: string,
    exportMode: string,
    exportFormat: string = '',
    fromSource: string = '',
    toSource: string = ''
  ): Promise<{ body: genericApiResponse }> {
    return request
      .post(`${ENDPOINT}/export`)
      .send({
        ids,
        export_mode: exportMode,
        export_format: exportFormat,
        from_source: fromSource,
        to_source: toSource
      })
    ;
  }

  static requestExport(
    ids: string,
    exportMode: string,
    exportFormat: string,
    fromSource: string,
    toSource: string,
    context: React.Context<FlashContextInterface>
  ): void {
    SharedPlaylistApi
      .exportPlaylists(
        ids,
        exportMode,
        exportFormat,
        fromSource,
        toSource
      )
      .then((res: { body: genericApiResponse }) => {
        const status = ['notice', res.body.status];
        context.flashMessage(status);
      })
      .catch((res) => {
        const message = res.response && res.response.body ? res.response.body.error : defaultApiErrorMessage;
        const error = ['alert', message];
        context.flashMessage(error);
      })
    ;
  }

  static clonePlaylist(
    id: string,
    context: React.Context<FlashContextInterface>
  ): void {
    request
      .post(`${ENDPOINT}/${id}/save`)
      .then((res: { body: genericApiResponse }) => {
        const status = ['notice', res.body.status];
        context.flashMessage(status);
      })
      .catch((res) => {
        const message = res.response && res.response.body ? res.response.body.error : defaultApiErrorMessage;
        const error = ['alert', message];
        context.flashMessage(error);
      })
    ;
  }
}
