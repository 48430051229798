// user
export const EMAIL_REGEX = /^[^@\s]+@[^\s,@]+\.[^\s,@]+$/;
export const USERNAME_MIN_LENGTH = 4;
export const USERNAME_MAX_LENGTH = 30;
export const PASSWORD_MIN_LENGTH = 6;

// Search
export const SEARCH_TERM_MIN_LENGTH = 4;

// Contact
export const CONTACT_MESSAGE_MIN_LENGTH = 10;

// Playlist
export const PLAYLIST_NAME_MIN_LENGTH = 1;
export const PLAYLIST_NAME_MAX_LENGTH = 250;
export const PLAYLIST_DESCRIPTION_MAX_LENGTH = 1200;
