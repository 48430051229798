import * as React from 'react';

import SharedPlaylistApi  from '@api/SharedPlaylistApi';
import FlashContext       from '@contexts/FlashContext';
import SharedHomeView     from '@components/Shared/SharedHomeView';

import { PlaylistInterface }  from '@components/Playlist/PlaylistInterface';
import { UserInterface }      from '@components/User/UserInterface';

import type { PlaylistSortOptionsType } from '@components/ManagePlaylists/PlaylistSortOptionsType';
import type { ViewModeType }            from '@components/ManagePlaylists/ViewModes/ViewModeType';
import { PlaylistSortOptions }          from '@components/ManagePlaylists/PlaylistSortOptions';

import LocalStorage from '@resources/helpers/LocalStorage';

interface SharedHomeProps {
  playlistsCount: number;
  user?: UserInterface;
}

interface SharedHomeState {
  loading:        boolean;
  page:           number;
  pages:          number;
  playlists:      Array<PlaylistInterface>;
  searchTerm:     string;
  sortingBy:      PlaylistSortOptionsType;
  totalPlaylists: number;
  viewMode:       ViewModeType
}

// @todo DRY (ManageHome.tsx)
const availableViewModes = ['cards', 'list'];
const sortingByStorageKey = 'playlists.sortingBy';
const viewModeStorageKey = 'playlists.viewMode';

export default class SharedHome extends React.Component<SharedHomeProps, SharedHomeState> {
  static contextType = FlashContext;

  static defaultProps = {
    user: null
  };

  constructor(props: SharedHomeProps) {
    super(props);

    this.state = {
      loading: false,
      page: 1,
      pages: 0,
      playlists: [],
      searchTerm: '',
      sortingBy: this.getSortingByFromStorage(),
      totalPlaylists: props.playlistsCount,
      viewMode: this.getViewModeFromStorage()
    };
  }

  componentDidMount(): void {
    this.fetchSharedPlaylists();
  }

  // @todo DRY
  getSortingByFromStorage(): PlaylistSortOptionsType {
    let sortingBy: PlaylistSortOptionsType = 'synced_at_asc';

    const sortingByFromStorage: PlaylistSortOptionsType = LocalStorage.get(sortingByStorageKey);

    if (!sortingByFromStorage) {
      this.writeSortingByToStorage(sortingBy);
    }

    if (Object.keys(PlaylistSortOptions).includes(sortingByFromStorage)) {
      sortingBy = sortingByFromStorage;
    }

    return sortingBy;
  }

  // @todo DRY
  getViewModeFromStorage(): ViewModeType {
    let viewMode: ViewModeType = 'cards';

    const viewModeFromStorage: ViewModeType = LocalStorage.get(viewModeStorageKey);

    if (!viewModeFromStorage) {
      this.writeViewModeToStorage(viewMode);
    }

    if (availableViewModes.includes(viewModeFromStorage)) {
      viewMode = viewModeFromStorage;
    }

    return viewMode;
  }

  handleOnSelectSort = (sortingBy: PlaylistSortOptionsType): void => {
    this.setState({ sortingBy }, () => {
      this.writeSortingByToStorage(sortingBy);
      this.fetchSharedPlaylists();
    });
  }

  handleOnPageChange = (event: React.ChangeEvent<unknown>, page: number): void => {
    this.setState({ page }, () => {
      this.fetchSharedPlaylists();
    });
  }

  handleOnSearchTermChange = (term: string): void => {
    this.setState({ searchTerm: term });
  }

  handleOnSearchTermSubmit = (): void => {
    this.setState({ page: 0 }, () => {
      this.fetchSharedPlaylists();
    });
  }

  handleOnViewModeChange = (viewMode: ViewModeType): void => {
    this.setState({ viewMode }, () => {
      this.writeViewModeToStorage(viewMode);
    });
  }

  fetchSharedPlaylists(): void {
    const {
      page,
      searchTerm,
      sortingBy
    } = this.state;

    const { order, sortBy } = PlaylistSortOptions[sortingBy];

    this.setState({ loading: true });

    SharedPlaylistApi
      .getPlaylists(
        order,
        page,
        searchTerm,
        sortBy
      )
      .then((res) => {
        const {
          pages,
          playlists,
          total
        } = res.body;

        const nextPage = res.body.page;

        this.setState({
          loading: false,
          page: nextPage,
          pages,
          playlists,
          totalPlaylists: total
        });
      })
      .catch(() => {
        this.setState({ loading: false });
      })
    ;
  }

  // @todo DRY
  writeSortingByToStorage(sortingBy: PlaylistSortOptionsType): void {
    LocalStorage.set(sortingByStorageKey, sortingBy);
  }

  // @todo DRY
  writeViewModeToStorage(viewMode: ViewModeType): void {
    LocalStorage.set(viewModeStorageKey, viewMode);
  }

  render(): JSX.Element {
    const {
      loading,
      pages,
      playlists,
      searchTerm,
      sortingBy,
      totalPlaylists,
      viewMode
    } = this.state;

    const {
      user
    } = this.props;

    const userId = user && user.id ? user.id : null;

    return (
      <SharedHomeView
        loading={loading}
        onPageChange={this.handleOnPageChange}
        onSelectSort={this.handleOnSelectSort}
        onSearchTermChange={this.handleOnSearchTermChange}
        onSearchTermSubmit={this.handleOnSearchTermSubmit}
        onViewModeChange={this.handleOnViewModeChange}
        pages={pages}
        playlists={playlists}
        playlistsCount={totalPlaylists}
        searchTerm={searchTerm}
        sortingBy={sortingBy}
        userId={userId}
        viewMode={viewMode}
      />
    );
  }
}
