import * as React from 'react';

import PlaylistHomeView           from '@components/Playlist/PlaylistHomeView';
import { PlaylistInterface }      from '@components/Playlist/PlaylistInterface';
import { PlaylistTrackInterface } from '@components/Playlist/PlaylistTrackInterface';
import { ShareTypes }             from '@components/Playlist/SharePlaylistMenu/ShareTypes';
import { UserInterface }          from '@components/User/UserInterface';

import PlaylistApi from '@api/PlaylistApi';
import SharedPlaylistApi from '@api/SharedPlaylistApi';

interface PlaylistHomeProps {
  playlist: PlaylistInterface;
  publicView: boolean;
  user?: UserInterface
}

interface PlaylistHomeState {
  loading:    boolean;
  order:      string;
  page:       number;
  perPage:    number;
  searchTerm: string;
  sortBy:     string;
  total:      number;
  tracks:     Array<PlaylistTrackInterface>;
}

export default class PlaylistHome extends React.Component<PlaylistHomeProps, PlaylistHomeState> {
  state = {
    loading: false,
    order: 'asc',
    total: 0,
    page: 0,
    perPage: 25,
    searchTerm: '',
    sortBy: null,
    tracks: []
  }

  componentDidMount(): void {
    this.fetchPlaylistTracks();
  }

  handleOnSearchTermChange = (term: string): void => {
    this.setState({ searchTerm: term });
  }

  handleOnSearchTermSubmit = (): void => {
    this.fetchPlaylistTracks();
  }

  handlePageChange = (page: number, perPage: number = 0) => {
    this.setState({ page, perPage }, () => this.fetchPlaylistTracks());
  }

  handleOnSortOrderChange = (sortBy: string, order: string) => {
    this.setState({ sortBy, order }, () => this.fetchPlaylistTracks());
  }

  fetchPlaylistTracks(): void {
    const {
      playlist,
      publicView
    } = this.props;

    const {
      order,
      page,
      perPage,
      searchTerm,
      sortBy
    } = this.state;

    this.setState({ loading: true });

    const api = publicView ? SharedPlaylistApi : PlaylistApi;

    api
      .getPlaylistTracks(playlist.id, page, perPage, searchTerm, sortBy, order)
      .then((res) => {
        const {
          playlistTracks,
          total
        } = res.body;

        const nextPage = res.body.page;

        this.setState({
          loading: false,
          page: nextPage,
          total,
          tracks: playlistTracks
        });
      })
      .catch(() => {
        // error
        this.setState({ loading: false });
      })
    ;
  }

  render(): React.ReactNode {
    const {
      loading,
      order,
      page,
      searchTerm,
      sortBy,
      total,
      tracks
    } = this.state;

    const {
      playlist,
      publicView,
      user
    } = this.props;

    const userId = user && user.id ? user.id : null;

    return (
      <PlaylistHomeView
        loading={loading}
        onSearchTermChange={this.handleOnSearchTermChange}
        onSearchTermSubmit={this.handleOnSearchTermSubmit}
        onSortOrderChange={this.handleOnSortOrderChange}
        onPageChange={this.handlePageChange}
        order={order}
        page={page}
        searchTerm={searchTerm}
        sortBy={sortBy}
        total={total}
        tracks={tracks}
        playlist={playlist}
        publicView={publicView && playlist.shareType !== ShareTypes.Private}
        userId={userId}
      />
    );
  }
}
