import * as React from 'react';

import Box          from '@material-ui/core/Box';
import Breadcrumbs  from '@material-ui/core/Breadcrumbs';
import Grid         from '@material-ui/core/Grid';
import Link         from '@material-ui/core/Link';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import { ExportSummaryInterface } from '@components/Summaries/Export/ExportSummaryInterface';
import ExportedPlaylistSummary    from '@components/Summaries/Export/common/ExportedPlaylistSummary';

import DefaultTheme from '@themes/DefaultTheme';
import Text         from '@styles/Text';
import ProviderLogo from '@components/Providers/common/ProviderLogo';

interface ExportSummaryViewProps {
  summary: ExportSummaryInterface;
}

const useStyles = makeStyles(({ breakpoints, spacing }: DefaultTheme) => createStyles({
  headingGrid: {
    marginBottom: spacing(5)
  },
  headerBox: {
    marginBottom: spacing(5)
  },
  exportedGridItem: {
    marginBottom: spacing(5),
    [breakpoints.up('sm')]: {
      textAlign: 'right'
    }
  },
  exportedText: {
    marginBottom: spacing(3)
  }
}));

export default function ExportSummaryView({ summary }: ExportSummaryViewProps): JSX.Element {
  const classes   = useStyles();
  const playlist  = summary.playlists[0];

  return (
    <>
      <Grid item xs={12} className={classes.headingGrid}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" href="/playlists">
                <Text type="label">
                  Playlists
                </Text>
              </Link>

              <Link color="inherit" href={`/playlists/${playlist.id}`}>
                <Text type="label">
                  {playlist.name}
                </Text>
              </Link>

              <Text type="label" color="secondary">
                Export Summary
              </Text>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems="flex-start">
              <Grid item xs={8}>
                <Box className={classes.headerBox}>
                  <Text type="heading">
                    Export Summary
                  </Text>
                </Box>
              </Grid>
              <Grid item xs={12} md={4} className={classes.exportedGridItem}>
                <Text type="label" color="secondary" className={classes.exportedText}>
                  {`Exported on ${summary.createdAt} to`}
                </Text>

                <ProviderLogo providerSource={summary.providerSource} height={40} />
              </Grid>
              <Grid item xs={12} md={10}>
                <Box className={classes.headerBox}>
                  <Text type="displayMd" color="secondary" weight={500}>
                    {playlist.name}
                  </Text>

                  <Text type="displayXs">
                    {`${playlist.trackCount} tracks`}
                  </Text>
                </Box>

                <ExportedPlaylistSummary playlist={playlist} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
