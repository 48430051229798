import * as React from 'react';

import PlaylistApi    from '@api/PlaylistApi';
import FlashContext   from '@contexts/FlashContext';
import ManageHomeView from '@components/ManagePlaylists/ManageHomeView';
import {
  PlaylistInterface,
  orphanPlaylistSourceKey,
  orphanPlaylistSourceOption
} from '@components/Playlist/PlaylistInterface';

import type { PlaylistSortOptionsType } from '@components/ManagePlaylists/PlaylistSortOptionsType';
import type { ViewModeType }            from '@components/ManagePlaylists/ViewModes/ViewModeType';
import { PlaylistSortOptions }          from '@components/ManagePlaylists/PlaylistSortOptions';
import { UserPlaylistSourcesInterface } from '@components/User/UserPlaylistSourcesInterface';
import {
  UserInterface,
  UserProvidersInterface
} from '@components/User/UserInterface';

import LocalStorage   from '@resources/helpers/LocalStorage';
import LocationHelper from '@resources/helpers/LocationHelper';

interface ManageHomeProps {
  playlistsCount: number;
  user: UserInterface;
}

interface ManageHomeState {
  inEditMode:             boolean;
  loading:                boolean;
  page:                   number;
  pages:                  number;
  playlists:              Array<PlaylistInterface>;
  searchTerm:             string;
  selectedPlaylistIds:    Array<PlaylistInterface['id']>;
  selectedSourceFilters:  Array<UserPlaylistSourcesInterface>;
  sortingBy:              PlaylistSortOptionsType;
  sourceFilters:          Array<string>;
  totalPlaylists:         number;
  viewMode:               ViewModeType;
}

const availableViewModes = ['cards', 'list'];
const sortingByStorageKey = 'playlists.sortingBy';
const viewModeStorageKey = 'playlists.viewMode';
const sourceFiltersStorageKey = 'playlists.sources';
const selectedPlaylistIdsStorageKey = 'playlists.selectedIds';
const storageValueSeparator = ',';

export default class ManageHome extends React.Component<ManageHomeProps, ManageHomeState> {
  static contextType = FlashContext;

  constructor(props: ManageHomeProps) {
    super(props);

    // Attempt to load user-selected source filters from Url first, then fallback to local storage
    let predefinedSourceFilters = this.getSourceFiltersFromUrl();
    if (!predefinedSourceFilters || predefinedSourceFilters.length === 0) {
      predefinedSourceFilters = this.getSourceFiltersFromStorage();
    }

    this.state = {
      inEditMode: false,
      loading: false,
      page: 1,
      pages: 0,
      playlists: [],
      searchTerm: '',
      selectedPlaylistIds: this.getSelectedPlaylistIdsFromStorage(),
      selectedSourceFilters: predefinedSourceFilters,
      sourceFilters: predefinedSourceFilters.map((f: UserPlaylistSourcesInterface) => f.value),
      sortingBy: this.getSortingByFromStorage(),
      totalPlaylists: props.playlistsCount,
      viewMode: this.getViewModeFromStorage()
    };
  }

  componentDidMount(): void {
    this.fetchPlaylists();
  }

  handleOnEditModeClick = (): void => {
    this.setState((prevState: ManageHomeState) => ({ inEditMode: !prevState.inEditMode }));
  }

  handleOnEditSelectItem = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    const currentSelection = this.state.selectedPlaylistIds;
    const selectedPlaylistId = event.currentTarget.value;

    let newSelection: any[];

    if (!checked && currentSelection.includes(selectedPlaylistId)) {
      newSelection = [...currentSelection]
        .filter((playlistId: PlaylistInterface['id']) => playlistId !== selectedPlaylistId);
    } else {
      newSelection = [...currentSelection, selectedPlaylistId];
    }

    this.setState({ selectedPlaylistIds: newSelection }, () => {
      this.writeSelectedPlaylistIdsToStorage(newSelection);
    });
  }

  handleOnSelectAllPlaylists = (): void => {
    const { playlists, selectedPlaylistIds } = this.state;
    const pagePlaylistIds = playlists.map((playlist: PlaylistInterface) => playlist.id);

    this.setState({ selectedPlaylistIds: [...new Set([...selectedPlaylistIds, ...pagePlaylistIds])]});
  }

  handleOnDeselectAllPlaylists = (): void => {
    this.setState({ selectedPlaylistIds: []});
  }

  handleOnDeleteSelectedPlaylists = (): void => {
    const { selectedPlaylistIds } = this.state;

    if (selectedPlaylistIds.length === 0) {
      return;
    }

    this.deletePlaylists(selectedPlaylistIds);
  }

  handleOnDeleteAllPlaylists = (): void => {
    this.deletePlaylists();
  }

  handleOnSelectSort = (sortingBy: PlaylistSortOptionsType): void => {
    this.setState({ sortingBy }, () => {
      this.writeSortingByToStorage(sortingBy);
      this.fetchPlaylists();
    });
  }

  handleOnPageChange = (event: React.ChangeEvent<unknown>, page: number): void => {
    this.setState({ page }, () => {
      this.fetchPlaylists();
    });
  }

  handleOnViewModeChange = (viewMode: ViewModeType): void => {
    this.setState({ viewMode }, () => {
      this.writeViewModeToStorage(viewMode);
    });
  }

  handleOnSourceFilterChange = (sourceFilters: Array<string>): void => {
    this.setState({ sourceFilters }, () => {
      this.writeSourceFiltersToStorage(sourceFilters);
      this.fetchPlaylists();
    });
  }

  handleOnSearchTermChange = (term: string): void => {
    this.setState({ searchTerm: term });
  }

  handleOnSearchTermSubmit = (): void => {
    // const { searchTerm } = this.state;
    // if (searchTerm.length === 0) {
    //   return;
    // }

    this.setState({ page: 0 }, () => {
      this.fetchPlaylists();
    });
  }

  getSortingByFromStorage(): PlaylistSortOptionsType {
    let sortingBy: PlaylistSortOptionsType = 'synced_at_asc';

    const sortingByFromStorage: PlaylistSortOptionsType = LocalStorage.get(sortingByStorageKey);

    if (!sortingByFromStorage) {
      this.writeSortingByToStorage(sortingBy);
    }

    if (Object.keys(PlaylistSortOptions).includes(sortingByFromStorage)) {
      sortingBy = sortingByFromStorage;
    }

    return sortingBy;
  }

  getViewModeFromStorage(): ViewModeType {
    let viewMode: ViewModeType = 'cards';

    const viewModeFromStorage: ViewModeType = LocalStorage.get(viewModeStorageKey);

    if (!viewModeFromStorage) {
      this.writeViewModeToStorage(viewMode);
    }

    if (availableViewModes.includes(viewModeFromStorage)) {
      viewMode = viewModeFromStorage;
    }

    return viewMode;
  }

  getSelectedPlaylistIdsFromStorage(): Array<PlaylistInterface['id']> {
    const selectedPlaylistIdsFromStorage = LocalStorage.get(selectedPlaylistIdsStorageKey);

    return !selectedPlaylistIdsFromStorage ? [] : selectedPlaylistIdsFromStorage.split(storageValueSeparator);
  }

  getSourceFiltersFromStorage(): Array<UserPlaylistSourcesInterface> {
    const sourceFiltersFromStorage = LocalStorage.get(sourceFiltersStorageKey);

    if (!sourceFiltersFromStorage) {
      return [];
    }

    return this.filterUserSelectedSources(sourceFiltersFromStorage);
  }

  getSourceFiltersFromUrl(): Array<UserPlaylistSourcesInterface> {
    const locationHelper = new LocationHelper();
    const urlFilters = locationHelper.getParam('sources');

    if (!urlFilters) {
      return [];
    }

    return this.filterUserSelectedSources(urlFilters);
  }

  filterUserSelectedSources(filters: string): Array<UserPlaylistSourcesInterface> {
    const { user }      = this.props;
    const filtersArray  = filters.split(storageValueSeparator);

    const filteredSources = user
      .providers
      .filter((provider: UserProvidersInterface) => filtersArray.includes(provider.source))
      .map((provider: UserProvidersInterface) => ({
        label: provider.name,
        value: provider.source
      }))
    ;

    if (user.hasOrphanPlaylists && filtersArray.includes(orphanPlaylistSourceKey)) {
      filteredSources.push(orphanPlaylistSourceOption);
    }

    return filteredSources;
  }

  writeSortingByToStorage(sortingBy: PlaylistSortOptionsType): void {
    LocalStorage.set(sortingByStorageKey, sortingBy);
  }

  writeViewModeToStorage(viewMode: ViewModeType): void {
    LocalStorage.set(viewModeStorageKey, viewMode);
  }

  writeSelectedPlaylistIdsToStorage(selectedPlaylistIds: Array<PlaylistInterface['id']>): void {
    LocalStorage.set(selectedPlaylistIdsStorageKey, selectedPlaylistIds.join(storageValueSeparator));
  }

  writeSourceFiltersToStorage(sources: Array<string>): void {
    LocalStorage.set(sourceFiltersStorageKey, sources.join(storageValueSeparator));
  }

  deletePlaylists(ids = []): void {
    const {
      searchTerm,
      sourceFilters
    } = this.state;

    this.setState({ loading: true });

    PlaylistApi
      .deletePlaylists(ids, searchTerm, sourceFilters)
      .then((res) => {
        const status = ['notice', res.body.status];

        this.removeDeletedPlaylistIds(ids);
        this.context.flashMessage(status);

        setTimeout(() => { window.location.reload(); }, 1500);
      })
      .catch(() => {
        const status = ['alert', 'Your delete request failed. Please try again or contact us for support.'];
        this.context.flashMessage(status);
      })
    ;
  }

  removeDeletedPlaylistIds(ids: Array<PlaylistInterface['id']>): void {
    const idsSet = new Set(ids);
    const { selectedPlaylistIds } = this.state;

    const newSelection = selectedPlaylistIds.filter((id) => !idsSet.has(id));
    this.setState({ selectedPlaylistIds: newSelection }, () => {
      this.writeSelectedPlaylistIdsToStorage(newSelection);
    });
  }

  fetchPlaylists(): void {
    const {
      page,
      searchTerm,
      sortingBy,
      sourceFilters
    } = this.state;

    const { order, sortBy } = PlaylistSortOptions[sortingBy];

    this.setState({ loading: true });

    PlaylistApi
      .getPlaylists(
        order,
        page,
        searchTerm,
        sortBy,
        sourceFilters
      )
      .then((res) => {
        const {
          pages,
          playlists,
          total
        } = res.body;

        const nextPage = res.body.page;

        this.setState({
          loading: false,
          page: nextPage,
          pages,
          playlists,
          totalPlaylists: total
        });
      })
      .catch(() => {
        console.log('@todo: handle playlists load error');
        this.setState({ loading: false });
      })
    ;
  }

  render(): React.ReactNode {
    const {
      inEditMode,
      selectedPlaylistIds,
      selectedSourceFilters,
      loading,
      pages,
      playlists,
      searchTerm,
      sortingBy,
      totalPlaylists,
      viewMode
    } = this.state;

    const {
      user
    } = this.props;

    return (
      <ManageHomeView
        defaultPlaylistShareType={user.defaultPlaylistShareType}
        loading={loading}
        onClickEditMode={this.handleOnEditModeClick}
        onEditSelectItem={this.handleOnEditSelectItem}
        onEditSelectAllClick={this.handleOnSelectAllPlaylists}
        onEditDeselectAllClick={this.handleOnDeselectAllPlaylists}
        onDeleteSelectedPlaylists={this.handleOnDeleteSelectedPlaylists}
        onDeleteAllPlaylists={this.handleOnDeleteAllPlaylists}
        onPageChange={this.handleOnPageChange}
        onSelectSort={this.handleOnSelectSort}
        onSourceFilterChange={this.handleOnSourceFilterChange}
        onSearchTermChange={this.handleOnSearchTermChange}
        onSearchTermSubmit={this.handleOnSearchTermSubmit}
        onViewModeChange={this.handleOnViewModeChange}
        inEditMode={inEditMode}
        selectedPlaylistIds={selectedPlaylistIds}
        selectedSourceFilters={selectedSourceFilters}
        pages={pages}
        playlists={playlists}
        playlistsCount={totalPlaylists}
        searchTerm={searchTerm}
        sortingBy={sortingBy}
        viewMode={viewMode}
      />
    );
  }
}
