import * as React from 'react';

import Button from '@material-ui/core/Button';
import Grid   from '@material-ui/core/Grid';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import ProviderList           from '@components/Providers/ProviderList';
import { ProvidersInterface } from '@components/Providers/ProvidersInterface';
import { UserStatsInterface } from '@components/Dashboard/UserStatsInterface';
import QuickSettings          from '@components/Dashboard/QuickSettings';
import UserStats              from '@components/Dashboard/UserStats';

import { UserInterface } from '@components/User/UserInterface';
import DefaultTheme from '@themes/DefaultTheme';
import Text         from '@styles/Text';

interface UserDashboardProps {
  providers: ProvidersInterface;
  ssoState: string;
  stats: UserStatsInterface;
  user: UserInterface;
}

const useStyles = makeStyles(({ spacing }: DefaultTheme) => createStyles({
  managePlaylistsBtn: {
    marginTop: spacing(2)
  },
  quickSettings: {
    marginTop: spacing(4) * 2
  }
}));

export default function UserDashboard({
  providers,
  ssoState,
  stats,
  user
}: UserDashboardProps): JSX.Element {
  const classes = useStyles();

  const handleOnManagePlaylistsClick = () => {
    window.location.href = '/playlists';
  };

  const handleOnViewSharedPlaylistsClick = () => {
    window.location.href = '/shared';
  };

  return (
    <>
      <Grid item xs={12}>
        <Text type="heading">
          Dashboard
        </Text>
      </Grid>
      <Grid item xs={12} md={7} lg={8}>
        <Grid item xs={12} md={5}>
          <UserStats stats={stats} />

          <Button
            fullWidth
            className={classes.managePlaylistsBtn}
            color="primary"
            variant="contained"
            size="large"
            onClick={handleOnManagePlaylistsClick}
          >
            Manage Playlists
          </Button>

          <Button
            fullWidth
            className={classes.managePlaylistsBtn}
            color="secondary"
            variant="contained"
            size="large"
            onClick={handleOnViewSharedPlaylistsClick}
          >
            View Shared Playlists
          </Button>
        </Grid>
        <Grid item xs={12} className={classes.quickSettings}>
          <QuickSettings userSettings={{ defaultPlaylistShareType: user.defaultPlaylistShareType }} />
        </Grid>
      </Grid>
      <Grid item xs={12} md={5} lg={4}>
        <ProviderList
          providers={providers}
          ssoState={ssoState}
        />
      </Grid>
    </>
  );
}
