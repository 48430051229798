import * as React from 'react';

import PlaylistApi from '@api/PlaylistApi';
import FlashContext from '@contexts/FlashContext';
import SharePlaylistMenuView from '@components/Playlist/SharePlaylistMenu/SharePlaylistMenuView';

import { PlaylistInterface } from '@components/Playlist/PlaylistInterface';
import { ShareTypes } from '@components/Playlist/SharePlaylistMenu/ShareTypes';

interface SharePlaylistMenuState {
  dialogOpen: boolean;
  shareType: PlaylistInterface['shareType'];
}

interface SharePlaylistMenuProps {
  activeShareType: PlaylistInterface['shareType'];
  dialogOpenByDefault?: boolean;
  fromPlaylistPage?: boolean;
  onCloseDialog?: () => void;
  selectedIds: Array<PlaylistInterface['id']>;
}

export default class SharePlaylistMenu extends React.Component<SharePlaylistMenuProps, SharePlaylistMenuState> {
  static contextType = FlashContext;

  static defaultProps = {
    dialogOpenByDefault: false,
    fromPlaylistPage: false,
    onCloseDialog: () => {}
  }

  constructor(props: SharePlaylistMenuProps) {
    super(props);

    const {
      activeShareType,
      dialogOpenByDefault
    } = props;

    this.state = {
      dialogOpen: dialogOpenByDefault,
      shareType: activeShareType
    };
  }

  handleOnShareButtonClick = (): void => {
    this.setState({ dialogOpen: true });
  }

  handleOnShareDialogClose = (): void => {
    const { onCloseDialog } = this.props;

    this.setState({ dialogOpen: false });
    onCloseDialog();
  }

  handleOnChangeShareType = (event: React.ChangeEvent<HTMLInputElement>): void => {
    let newShareType = parseInt((event.target as HTMLInputElement).value, 10);

    if (newShareType !== ShareTypes.Private && newShareType !== ShareTypes.Link && newShareType !== ShareTypes.Public) {
      newShareType = ShareTypes.Private;
    }

    this.setState({ shareType: newShareType });
  }

  handleOnSave = (): void => {
    const { shareType } = this.state;
    const { selectedIds } = this.props;

    PlaylistApi
      .sharePlaylists(selectedIds, shareType)
      .then((res) => {
        const status = ['success', res.body.status];
        this.context.flashMessage(status);
      })
      .catch(() => {
        const status = ['alert', 'Failed to update playlist share settings. Please try again or contact us for support.'];
        this.context.flashMessage(status);
      })
    ;

    this.handleOnShareDialogClose();
  }

  render(): JSX.Element {
    const {
      dialogOpen,
      shareType
    } = this.state;

    const {
      fromPlaylistPage,
      selectedIds
    } = this.props;

    return (
      <SharePlaylistMenuView
        onClick={this.handleOnShareButtonClick}
        onClose={this.handleOnShareDialogClose}
        onChangeShareType={this.handleOnChangeShareType}
        onSave={this.handleOnSave}
        fromPlaylistPage={fromPlaylistPage}
        selectedIds={selectedIds}
        shareType={shareType}
        showDialog={dialogOpen}
      />
    );
  }
}
