import * as React from 'react';

import Box        from '@material-ui/core/Box';
import Grid       from '@material-ui/core/Grid';
import Pagination from '@material-ui/lab/Pagination';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import EditMode                         from '@components/ManagePlaylists/EditElements/EditMode';
import CardsView                        from '@components/ManagePlaylists/ViewModes/CardsView';
import ListView                         from '@components/ManagePlaylists/ViewModes/ListView';
import ActionBar                        from '@components/ManagePlaylists/ActionBar';
import { PlaylistInterface }            from '@components/Playlist/PlaylistInterface';
import { UserPlaylistSourcesInterface } from '@components/User/UserPlaylistSourcesInterface';
import type { PlaylistSortOptionsType } from '@components/ManagePlaylists/PlaylistSortOptionsType';

import Text         from '@styles/Text';
import DefaultTheme from '@themes/DefaultTheme';

interface ManageHomeViewProps {
  defaultPlaylistShareType: number;
  loading: boolean;
  onClickEditMode: () => void;
  onEditSelectItem: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  onEditSelectAllClick: () => void;
  onEditDeselectAllClick: () => void;
  onDeleteSelectedPlaylists: () => void;
  onDeleteAllPlaylists: () => void;
  onPageChange: (event: React.ChangeEvent<unknown>, page: number) => void;
  onSearchTermChange: (term: string) => void;
  onSearchTermSubmit: () => void;
  onSelectSort: (sortingBy: PlaylistSortOptionsType) => void;
  onSourceFilterChange: (sourceFilters: Array<string>) => void;
  onViewModeChange: (viewMode: string) => void;
  inEditMode: boolean;
  selectedPlaylistIds: Array<PlaylistInterface['id']>;
  selectedSourceFilters: Array<UserPlaylistSourcesInterface>;
  pages: number;
  playlists: Array<PlaylistInterface>;
  playlistsCount: number;
  searchTerm: string;
  sortingBy: PlaylistSortOptionsType;
  viewMode: string;
}

const useStyles = makeStyles(({ breakpoints, spacing }: DefaultTheme) => createStyles({
  root: {
    flexGrow: 1
  },
  bottomSpacing: {
    [breakpoints.down('sm')]: {
      marginBottom: spacing(3)
    },
    [breakpoints.up('sm')]: {
      marginBottom: spacing(5)
    }
  },
  paginationBox: {
    margin: `${spacing(3)}px auto ${spacing(5)}px auto`
  }
}));

export default function ManageHomeView({
  defaultPlaylistShareType,
  loading,
  onClickEditMode,
  onPageChange,
  onSelectSort,
  onSearchTermChange,
  onSearchTermSubmit,
  onSourceFilterChange,
  onViewModeChange,
  onEditSelectItem,
  onEditSelectAllClick,
  onEditDeselectAllClick,
  onDeleteSelectedPlaylists,
  onDeleteAllPlaylists,
  inEditMode,
  selectedPlaylistIds,
  selectedSourceFilters,
  pages,
  playlists,
  playlistsCount,
  searchTerm,
  sortingBy,
  viewMode
}: ManageHomeViewProps): JSX.Element {
  const classes = useStyles();

  let view = null;

  if (viewMode === 'cards') {
    view = (
      <CardsView
        inEditMode={inEditMode}
        loading={loading}
        onEditSelectItem={onEditSelectItem}
        playlists={playlists}
        selectedPlaylistIds={selectedPlaylistIds}
      />
    );
  } else {
    view = (
      <ListView
        inEditMode={inEditMode}
        loading={loading}
        onEditSelectItem={onEditSelectItem}
        playlists={playlists}
        selectedPlaylistIds={selectedPlaylistIds}
      />
    );
  }

  return (
    <>
      <Grid item xs={12} className={classes.bottomSpacing}>
        <Text type="heading" className={classes.bottomSpacing}>
          Manage Playlists
        </Text>
      </Grid>
      <Grid item xs={12}>
        <ActionBar
          onClickEditMode={onClickEditMode}
          onSelectSort={onSelectSort}
          onSearchTermChange={onSearchTermChange}
          onSearchTermSubmit={onSearchTermSubmit}
          onSourceFilterChange={onSourceFilterChange}
          onViewModeChange={onViewModeChange}
          inEditMode={inEditMode}
          selectedSourceFilters={selectedSourceFilters}
          playlistsCount={playlistsCount}
          searchTerm={searchTerm}
          sortingBy={sortingBy}
          viewMode={viewMode}
        />

        {inEditMode && (
          <EditMode
            onEditSelectAllClick={onEditSelectAllClick}
            onEditDeselectAllClick={onEditDeselectAllClick}
            onDeleteSelectedPlaylists={onDeleteSelectedPlaylists}
            onDeleteAllPlaylists={onDeleteAllPlaylists}
            defaultPlaylistShareType={defaultPlaylistShareType}
            selectedIds={selectedPlaylistIds}
          />
        )}

        <div className={classes.root}>
          <Grid container spacing={3}>
            {view}
          </Grid>
        </div>
      </Grid>

      {pages > 1 && (
        <Box className={classes.paginationBox}>
          <Pagination
            count={pages}
            boundaryCount={2}
            onChange={onPageChange}
            siblingCount={0}
            showFirstButton
            showLastButton
          />
        </Box>
      )}
    </>
  );
}
