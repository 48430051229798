import * as React from 'react';

import PlaylistMenuView from '@components/Playlist/PlaylistMenu/PlaylistMenuView';
import PlaylistApi      from '@api/PlaylistApi';

import FlashContext                   from '@contexts/FlashContext';
import { ExportTypes }                from '@components/ExportPlaylists/ExportTypes';
import { PlaylistInterface }          from '@components/Playlist/PlaylistInterface';
import { PlaylistMenuItemInterface }  from '@components/Playlist/common/PlaylistMenuItemInterface';
import ExportToProvider               from '@components/ExportPlaylists/ExportToProvider';
import { InternalProvidersEnum }      from '@components/Providers/InternalProvidersEnum';
import { defaultApiErrorMessage }     from '@api/BaseApi';

enum PlaylistMenuVariants {
  Menu = 'menu',
  Button = 'button'
}

type PlaylistMenuVariantType = keyof typeof PlaylistMenuVariants;

interface PlaylistMenuState {
  promptDeleteConfirmation: boolean;
  showExportToProviderDialog: boolean;
}

interface PlaylistMenuProps {
  anchorEl?: Element | null;
  onClose?: () => void;
  playlist: PlaylistInterface;
  variant: PlaylistMenuVariantType;
}

export default class PlaylistMenu extends React.Component<PlaylistMenuProps, PlaylistMenuState> {
  static contextType = FlashContext;

  static defaultProps = {
    anchorEl: null,
    onClose: (): void => {}
  }

  constructor(props: PlaylistMenuProps) {
    super(props);

    this.state = {
      promptDeleteConfirmation: false,
      showExportToProviderDialog: false
    };
  }

  handleOnSyncClick = (): void => {
    const {
      onClose,
      playlist
    } = this.props;

    PlaylistApi
      .syncPlaylist(playlist.id)
      .then((res) => {
        const status = ['notice', res.body.status];
        this.context.flashMessage(status);
      })
      .catch((res) => {
        const message = res.response && res.response.body ? res.response.body.error : defaultApiErrorMessage;
        const error = ['alert', message];
        this.context.flashMessage(error);
      })
    ;

    onClose();
  }

  // handleOnSnapshotClick = (): void => {
  //   // @todo implement
  // }

  handleOnExportToProviderClick = (): void => {
    this.setState({ showExportToProviderDialog: true }, () => {
      this.props.onClose();
    });
  }

  handleOnExportAsFileClick = (exportType: ExportTypes): void => {
    const {
      onClose,
      playlist
    } = this.props;

    let exportFormat = '';

    if (exportType === ExportTypes.Csv) {
      exportFormat = 'csv';
    } else if (exportType === ExportTypes.Json) {
      exportFormat = 'json';
    }

    PlaylistApi.requestExport(
      playlist.id,
      'file',
      exportFormat,
      '',
      '',
      this.context
    );

    onClose();
  }

  handleOnDeleteClick = (): void => {
    this.setState({ promptDeleteConfirmation: true }, () => {
      this.props.onClose();
    });
  }

  handleOnConfirmDelete = (): void => {
    this.setState({ promptDeleteConfirmation: false });

    const { playlist } = this.props;

    PlaylistApi
      .deletePlaylist(playlist.id)
      .then((res) => {
        window.location.href = '/playlists';
      })
      .catch((res) => {
        const message = res.response && res.response.body ? res.response.body.error : defaultApiErrorMessage;
        const error = ['alert', message];
        this.context.flashMessage(error);
      })
    ;
  }

  handleOnCloseDeleteDialog = (): void => this.setState({ promptDeleteConfirmation: false });
  handleOnCloseExportDialog = (): void => this.setState({ showExportToProviderDialog: false });

  render(): JSX.Element {
    const {
      promptDeleteConfirmation,
      showExportToProviderDialog
    } = this.state;

    const {
      anchorEl,
      onClose,
      playlist,
      variant
    } = this.props;

    const dividerMenuItem = {
      title: 'divider',
      onClick: () => {}
    };

    const menuItems: Array<PlaylistMenuItemInterface> = [
      // {
      //   title: 'Create Snapshot',
      //   onClick: this.handleOnSnapshotClick
      // },
      {
        title: 'Export to Provider',
        onClick: this.handleOnExportToProviderClick
      },
      {
        title: 'Export as CSV',
        onClick: () => this.handleOnExportAsFileClick(ExportTypes.Csv)
      },
      {
        title: 'Export as JSON',
        onClick: () => this.handleOnExportAsFileClick(ExportTypes.Json)
      },
      dividerMenuItem,
      {
        title: 'Delete',
        onClick: this.handleOnDeleteClick
      }
    ];

    if (!playlist.orphan && !(playlist.providerSource in InternalProvidersEnum)) {
      menuItems.unshift(
        {
          title: 'Re-Synchronize',
          onClick: this.handleOnSyncClick
        },
        dividerMenuItem
      );
    }

    const fromSourceInitialValue = playlist.orphan ? 'orphan' : playlist.providerSource;

    return (
      <>
        <PlaylistMenuView
          anchorEl={anchorEl}
          onClose={onClose}
          menuItems={menuItems}
          onCloseDeleteDialog={this.handleOnCloseDeleteDialog}
          onConfirmDelete={this.handleOnConfirmDelete}
          playlistName={playlist.name}
          promptDeleteConfirmation={promptDeleteConfirmation}
          variant={variant}
        />
        {showExportToProviderDialog && (
          <ExportToProvider
            fromSourceInitialValue={fromSourceInitialValue}
            onCloseExportDialog={this.handleOnCloseExportDialog}
            playlistIds={[playlist.id]}
          />
        )}
      </>
    );
  }
}
