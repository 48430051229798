import * as React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import UserSettingsApi from '@api/UserSettingsApi';
import { ShareTypes } from '@components/Playlist/SharePlaylistMenu/ShareTypes';
import { UserSettingsInterface } from '@components/User/UserSettingsInterface';
import {
  defaultApiErrorMessage,
  genericApiResponse
} from '@api/BaseApi';

import FlashContext from '@contexts/FlashContext';
import DefaultTheme from '@themes/DefaultTheme';
import Text         from '@styles/Text';

interface QuickSettingsProps {
  userSettings: UserSettingsInterface;
}

const useStyles = makeStyles(({ spacing }: DefaultTheme) => createStyles({
  root: {
    marginTop: spacing(5)
  },
  preferencesGrid: {
    marginTop: spacing(4)
  },
  subTitle: {
    fontStyle: 'italic'
  }
}));

export default function QuickSettings({ userSettings }: QuickSettingsProps): JSX.Element {
  const flash = React.useContext(FlashContext);
  const classes = useStyles();

  const [defaultPlaylistShareType, setdefaultPlaylistShareType] = React.useState(userSettings.defaultPlaylistShareType);

  const handleOnShareTypeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    let newShareType = parseInt((event.target as HTMLInputElement).value, 10);

    if (newShareType !== ShareTypes.Private && newShareType !== ShareTypes.Link && newShareType !== ShareTypes.Public) {
      newShareType = ShareTypes.Private;
    }

    UserSettingsApi
      .updateSettings(newShareType)
      .then((res: { body: genericApiResponse }) => {
        const status = ['success', res.body.status];
        flash.flashMessage(status);
        setdefaultPlaylistShareType(newShareType);
      })
      .catch((res) => {
        const message = res.response && res.response.body ? res.response.body.error : defaultApiErrorMessage;
        const error = ['alert', message];
        flash.flashMessage(error);
      })
    ;
  };

  return (
    <Box className={classes.root}>
      <Text type="displayMd">
        Quick Settings
      </Text>

      <Text type="regular" className={classes.subTitle}>
        Preferences are saved on change.
      </Text>

      <Grid container className={classes.preferencesGrid}>
        <Grid item>
          Default sharing preference for new playlists:

          <FormControl fullWidth component="fieldset">
            <RadioGroup
              aria-label="default_playlist_share_type"
              name="default_playlist_share_type"
              value={defaultPlaylistShareType}
              onChange={handleOnShareTypeChange}
            >
              <FormControlLabel value={ShareTypes.Private} control={<Radio size="small" />} label="Private" />
              <FormControlLabel value={ShareTypes.Link} control={<Radio size="small" />} label="Link-only access" />
              <FormControlLabel value={ShareTypes.Public} control={<Radio size="small" />} label="Public" />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
}
