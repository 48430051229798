import * as React from 'react';

import Avatar                   from '@material-ui/core/Avatar';
import Divider                  from '@material-ui/core/Divider';
import IconButton               from '@material-ui/core/IconButton';
import List                     from '@material-ui/core/List';
import ListItem                 from '@material-ui/core/ListItem';
import ListItemAvatar           from '@material-ui/core/ListItemAvatar';
import ListItemText             from '@material-ui/core/ListItemText';
import LinearProgress           from '@material-ui/core/LinearProgress';
import ListItemSecondaryAction  from '@material-ui/core/ListItemSecondaryAction';

import CloseIcon        from '@material-ui/icons/Close';
import NewReleasesIcon  from '@material-ui/icons/NewReleases';
import FileCopyIcon     from '@material-ui/icons/FileCopy';
import PublishIcon      from '@material-ui/icons/Publish';
import UploadIcon       from '@material-ui/icons/Description';
import SyncIcon         from '@material-ui/icons/Sync';
import CloneIcon        from '@material-ui/icons/Queue';
// import SyncProblemIcon  from '@material-ui/icons/SyncProblem';

import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import DefaultTheme from '@themes/DefaultTheme';
import Text         from '@styles/Text';

import { NotificationInterface } from '@components/Notifications/NotificationInterface';

const notificationTypeIcon = {
  AnnouncementNotification: <NewReleasesIcon />,
  PlaylistCloneNotification: <CloneIcon />,
  PlaylistExportNotification: <PublishIcon />,
  PlaylistImportNotification: <UploadIcon />,
  PlaylistSnapshotNotification: <FileCopyIcon />,
  PlaylistSyncNotification: <SyncIcon />,
  ProviderSyncNotification: <SyncIcon />
};

const useStyles = makeStyles(({ colors, spacing }: DefaultTheme) => createStyles({
  avatar: {
    backgroundColor: colors.primary,
    color: colors.lightGrey
  },
  list: {
    paddingBottom: 0
  },
  progressBar: {
    margin: `${spacing(2)}px 0`
  }
}));

interface NotificationListProps {
  notifications: Array<NotificationInterface>;
  onDismissClick: (notificationId: string) => void;
}

export default function NotificationList({
  notifications,
  onDismissClick
}: NotificationListProps): JSX.Element {
  const classes = useStyles();
  const defaultErrorMessage = 'Request failed, please try again.';
  const goToPath = (path: string, stage: number) => {
    if (stage === 1) {
      window.location.href = path;
    }
  };

  return notifications.length > 0
    ? (
      <List className={classes.list}>
        {notifications.map((notification: NotificationInterface) => {
          const n       = notification.attributes;
          const nType   = n.type.replace('Notifications::', '');
          const nIcon   = notificationTypeIcon[nType];
          const hasUrl  = n.url && n.stage === 1;

          let secondaryText = n.stage === 0
            ? (<LinearProgress className={classes.progressBar} />)
            : (
              <Text type="regular" color="grey">
                {`Completed. ${n.createdAtHuman}`}
              </Text>
            )
          ;

          if (n.stage === 2) {
            secondaryText = (
              <Text type="regular" color="red">
                {!n.errorMessage ? defaultErrorMessage : n.errorMessage}
              </Text>
            );
          }

          if (nType === 'AnnouncementNotification') {
            secondaryText = null;
          }

          const onClickHandler = function (url: string, stage: number, type: string) {
            if (type === 'AnnouncementNotification') {
              window.open(url);
            } else {
              goToPath(url, stage);
            }
          };

          return (
            <React.Fragment key={n.createdAt}>
              <ListItem
                button={hasUrl}
                onClick={() => onClickHandler(n.url, n.stage, nType)}
              >
                <ListItemAvatar>
                  <Avatar className={classes.avatar}>
                    {nIcon}
                  </Avatar>
                </ListItemAvatar>

                <ListItemText
                  disableTypography
                  primary={(
                    <Text type="label">
                      {n.content}
                    </Text>
                  )}
                  secondary={secondaryText}
                />

                {n.stage !== 0 && (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="dismiss"
                      title="Dismiss"
                      onClick={() => onDismissClick(n.id)}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
              <Divider />
            </React.Fragment>
          );
        })}
      </List>
    )
    : (
      <List className={classes.list}>
        <ListItem>
          <ListItemText secondary="Nothing new" />
        </ListItem>
      </List>
    )
  ;
}
