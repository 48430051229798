import request from 'superagent';

import { BASE_URL } from '@api/BaseApi';

const ENDPOINT = `${BASE_URL}/oauth`;

interface authAppleMusicUserResponse {
  message: string;
}

export default class OauthApi {
  static authAppleMusicUser(
    musicUserToken: string = null,
    storefrontId: string = null
  ): Promise<{ body: authAppleMusicUserResponse }> {
    return request
      .post(`${ENDPOINT}/apple_music_callback`)
      .send({
        music_user_token: musicUserToken,
        storefront_id: storefrontId
      })
    ;
  }
}
