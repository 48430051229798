import * as React from 'react';

import Grid from '@material-ui/core/Grid';

import { ViewModePropsInterface } from '@components/ManagePlaylists/ViewModes/ViewModePropsInterface';
import { PlaylistInterface }      from '@components/Playlist/PlaylistInterface';
import PlaylistListItem           from '@components/ManagePlaylists/ViewModes/PlaylistListItem';
import LoadingCard                from '@components/common/cards/LoadingCard';
import NoPlaylistsMessage         from '@components/ManagePlaylists/common/NoPlaylistsMessage';

export default function ListView({
  inEditMode,
  loading,
  onEditSelectItem,
  playlists,
  publicView = false,
  selectedPlaylistIds,
  userId = null
}: ViewModePropsInterface): JSX.Element {
  return loading
    ? (
      <>
        {[1, 2, 3, 4, 5].map((idx: number): JSX.Element => (
          <Grid key={`pl-loading-${idx}`} item xs={12}>
            <LoadingCard direction="horizontal" />
          </Grid>
        ))}
      </>
    )
    : (
      <>
        {playlists.length > 0
          ? playlists.map((playlist: PlaylistInterface): JSX.Element => (
            <Grid key={playlist.id} item xs={12}>
              <PlaylistListItem
                inEditMode={inEditMode}
                isSelected={selectedPlaylistIds.includes(playlist.id)}
                onEditSelectItem={onEditSelectItem}
                playlist={playlist}
                publicView={publicView}
                userId={userId}
              />
            </Grid>
          ))
          : (
            <NoPlaylistsMessage publicView={publicView} />
          )}
      </>
    )
  ;
}
