import * as React from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import { PlaylistInterface } from '@components/Playlist/PlaylistInterface';

import DefaultTheme from '@themes/DefaultTheme';
import Text         from '@styles/Text';

interface HtmlEmbedProps {
  playlist: PlaylistInterface;
}

const useStyles = makeStyles(({ spacing }: DefaultTheme) => createStyles({
  accordionRoot: {
    width: '100%',
    marginLeft: spacing(1),
    marginRight: spacing(1),
    marginBottom: spacing(2)
  }
}));

export default function HtmlEmbed({ playlist }: HtmlEmbedProps): JSX.Element {
  const classes = useStyles();

  let embed;

  if (playlist.sourceKind !== 1 || playlist.providerSource === 'appleMusic') {
    return null;
  }

  if (playlist.providerSource === 'spotify') {
    embed = (<div id="embed-iframe" />);

    window.onSpotifyIframeApiReady = (IFrameAPI) => {
      const element = document.getElementById('embed-iframe');
      const options = {
        uri: `spotify:playlist:${playlist.sourceId}`
      };
      const callback = (EmbedController) => {};
      IFrameAPI.createController(element, options, callback);
    };
  }
  // else if (playlist.providerSource === 'appleMusic') {
  // // https://js-cdn.music.apple.com/musickit/v3/docs/index.html?path=/docs/html-embeds--html-embeds
  //   embed = (
  //     <iframe
  //       src={`https://embed.music.apple.com/ca/playlist/${playlist.name}/${playlist.sourceId}?app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1`}
  //       height="450px"
  //       title="Apple Music embedded player"
  //       sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation"
  //       allow="autoplay *; encrypted-Media *;"
  //       style={{ width: '100%', overflow: 'hidden', borderRadius: '10px', backgroundColor: 'transparent' }}
  //     />
  //   );
  // }

  return (
    <Accordion className={classes.accordionRoot}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="embed-content"
        id="embed-header"
      >
        <Text type="label">
          Embedded player
        </Text>
      </AccordionSummary>
      <AccordionDetails>
        {embed}
      </AccordionDetails>
    </Accordion>
  );
}
