import * as React from 'react';

import Checkbox       from '@material-ui/core/Checkbox';
import TableHead      from '@material-ui/core/TableHead';
import TableRow       from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableCell, { SortDirection } from '@material-ui/core/TableCell';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import { TableHeadInterface } from '@components/common/table/TableHeadInterface';

interface TableHeadProps {
  headCells: Array<TableHeadInterface>;
  numSelected: number;
  onRequestSort: (property: string) => void;
  onSelectAllClick: () => void;
  order: SortDirection;
  orderBy: string;
  rowCount: number;
  publicView: boolean;
}

const useStyles = makeStyles(() => createStyles({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  }
}));

export default function EnhancedTableHead(props: TableHeadProps): JSX.Element {
  const classes = useStyles();

  const {
    headCells,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    publicView
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(property);
  };

  return (
    <TableHead>
      <TableRow>
        {!publicView && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all' }}
            />
          </TableCell>
        )}

        {headCells.map((headCell) => {
          const label = headCell.sort ? (
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          ) : (
            <>
              {headCell.label}
            </>
          );

          const cellStyle = {
            width: headCell.width || 'auto'
          };

          return (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
              style={cellStyle}
            >
              {label}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}
