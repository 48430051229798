import * as React from 'react';

import Paper            from '@material-ui/core/Paper';
import Table            from '@material-ui/core/Table';
import TableBody        from '@material-ui/core/TableBody';
import TableContainer   from '@material-ui/core/TableContainer';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import { defaultApiErrorMessage } from '@api/BaseApi';
import PlaylistTrackPagination    from '@components/Playlist/PlaylistTrackPagination';
import PlaylistTrackApi           from '@api/PlaylistTrackApi';
import TableHead                  from '@components/common/table/TableHead';
import TableToolbar               from '@components/common/table/TableToolbar';

import LoadingTableRow            from '@components/common/table/LoadingTableRow';
import TracksTableRow             from '@components/Playlist/tracks/TracksTableRow';
import { PlaylistTrackInterface } from '@components/Playlist/PlaylistTrackInterface';
import DefaultTheme               from '@themes/DefaultTheme';

import FlashContext from '@contexts/FlashContext';

interface TracksTableProps {
  loading: boolean;
  order: string;
  page: number;
  playlistId: string;
  rows: Array<PlaylistTrackInterface>;
  sortBy: string;
  total: number;
  onSortOrderChange: (sortBy: string, order: string) => void;
  onPageChange: (page: number, perPage: number) => void;
  publicView: boolean;
}

const useStyles = makeStyles(({ spacing }: DefaultTheme) => createStyles({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: spacing(2),
  },
  table: {
    minWidth: 750,
  }
}));

const headCells = [
  {
    id: 'artist',
    numeric: false,
    disablePadding: false,
    label: 'Artist',
    sort: true
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    sort: true
  },
  {
    id: 'albumName',
    numeric: false,
    disablePadding: false,
    label: 'Album',
    sort: true
  },
  {
    id: 'durationMs',
    numeric: true,
    disablePadding: false,
    label: 'Duration',
    sort: true
  },
  {
    id: 'addedAt',
    numeric: false,
    disablePadding: false,
    label: 'Added on',
    sort: true,
    width: 110
  }
];

export default function TracksTable({
  loading,
  order,
  page,
  playlistId,
  rows,
  sortBy,
  total,
  onSortOrderChange,
  onPageChange,
  publicView
}: TracksTableProps): JSX.Element {
  const classes = useStyles();
  const [selected, setSelected] = React.useState([]);
  const flash = React.useContext(FlashContext);

  const handleRequestSort = (property: string) => {
    const isAsc = sortBy === property && order === 'asc';
    const newOrder = (isAsc ? 'desc' : 'asc');

    onSortOrderChange(property, newOrder);
  };

  const handleSelectAllClick = (event: React.SyntheticEvent) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }

    setSelected([]);
  };

  const handleDeleteClick = (event: React.SyntheticEvent) => {
    if (publicView || selected.length === 0) {
      return;
    }

    PlaylistTrackApi
      .deletePlaylistTracks(playlistId, selected)
      .then((res) => {
        window.location.href = `/playlists/${playlistId}`;
        flash.flashMessage(['notice', res.body.status]);
      })
      .catch((res) => {
        const message = res.response && res.response.body ? res.response.body.error : defaultApiErrorMessage;
        const error = ['alert', message];
        flash.flashMessage(error);
      })
    ;
  };

  const handleOnRowClick = (id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const tableRows = loading ? (
    <LoadingTableRow cells={headCells.length + 1} />
  ) : (
    <>
      {rows.map((row, index) => (
        <TracksTableRow
          key={row.id}
          index={index}
          onClick={handleOnRowClick}
          row={row}
          selected={selected}
          publicView={publicView}
        />
      ))}
    </>
  );

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {!publicView && (
          <TableToolbar
            numSelected={selected.length}
            onDeleteClick={handleDeleteClick}
          />
        )}

        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
            stickyHeader
          >
            <TableHead
              headCells={headCells}
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={sortBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              publicView={publicView}
            />
            <TableBody>
              {tableRows}
            </TableBody>
          </Table>
        </TableContainer>

        <PlaylistTrackPagination
          currentPage={page}
          totalRecords={total}
          onPageChange={onPageChange}
        />
      </Paper>
    </div>
  );
}
