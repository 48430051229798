import * as React from 'react';

import MenuVariantView    from '@components/Playlist/PlaylistMenu/MenuVariantView';
import ButtonVariantView  from '@components/Playlist/PlaylistMenu/ButtonVariantView';
import ConfirmationDialog from '@components/common/ConfirmationDialog';

import { PlaylistMenuItemInterface }  from '@components/Playlist/common/PlaylistMenuItemInterface';

interface PlaylistMenuViewProps {
  anchorEl?: Element | null;
  menuItems: Array<PlaylistMenuItemInterface>;
  onClose?: () => void;
  onCloseDeleteDialog: () => void;
  onConfirmDelete: () => void;
  playlistName: string;
  promptDeleteConfirmation: boolean;
  variant: string;
}

export default function PlaylistMenuView({
  anchorEl,
  onClose,
  onCloseDeleteDialog,
  onConfirmDelete,
  menuItems,
  playlistName,
  promptDeleteConfirmation,
  variant
}: PlaylistMenuViewProps): JSX.Element {
  const menu = variant === 'Menu'
    ? (
      <MenuVariantView
        anchorEl={anchorEl}
        items={menuItems}
        onClose={onClose}
      />
    ) : (
      <ButtonVariantView
        items={menuItems}
      />
    )
  ;

  return (
    <>
      {menu}
      {promptDeleteConfirmation && (
        <ConfirmationDialog
          title="Delete Playlist"
          content={(
            <>
              {`Delete "${playlistName}"?`}
              <br />
              Please notice that the selected playlist will only be removed from Playlists.cloud.
            </>
          )}
          confirmLabel="Delete"
          onClose={onCloseDeleteDialog}
          onConfirm={onConfirmDelete}
          isOpen={promptDeleteConfirmation}
        />
      )}
    </>
  );
}
