import * as React from 'react';

import Hidden   from '@material-ui/core/Hidden';
import Divider  from '@material-ui/core/Divider';

import MenuView             from '@components/Header/MenuView';
import PublicUserMenuItems  from '@components/Header/MenuItems/PublicUserMenuItems';
import UserMenuItems        from '@components/Header/MenuItems/UserMenuItems';
import CommonMenuItems      from '@components/Header/MenuItems/CommonMenuItems';

import { UserInterface }                   from '@components/User/UserInterface';
import { AppStatsInterface }               from '@components/common/AppStatsInterface';
import { NotificationCollectionInterface } from '@components/Notifications/NotificationCollectionInterface';

/** @todo review location prop */
interface MenuProps {
  location: string;
  notificationCollection: NotificationCollectionInterface;
  user?: UserInterface;
  appStats?: AppStatsInterface;
}

interface MenuState {
  isMobileMenuVisible: boolean;
}

export default class Menu extends React.PureComponent<MenuProps, MenuState> {
  constructor(props: MenuProps) {
    super(props);

    this.state = {
      isMobileMenuVisible: false
    };
  }

  handleMobileMenuToggle = () => {
    this.setState((prevState: MenuState): MenuState => ({
      isMobileMenuVisible: !prevState.isMobileMenuVisible
    }));
  };

  render(): JSX.Element {
    const {
      isMobileMenuVisible
    } = this.state;

    const {
      appStats,
      location,
      notificationCollection,
      user
    } = this.props;

    const activeLink = location;
    const isAuthenticated = Object.keys(user).length > 0 && user.id !== '0';

    let userMenu = (
      <PublicUserMenuItems
        activeLink={activeLink}
      />
    );

    if (isAuthenticated) {
      userMenu = (
        <UserMenuItems
          appStats={appStats}
          notificationCollection={notificationCollection}
          username={user.username}
        />
      );
    }

    const menuItems = (
      <>
        <Hidden smUp>
          <Divider variant="middle" />
        </Hidden>

        {userMenu}

        <CommonMenuItems activeLink={activeLink} />
      </>
    );

    return (
      <MenuView
        appStats={appStats}
        isAuthenticated={isAuthenticated}
        isMobileMenuVisible={isMobileMenuVisible}
        onMobileMenuToggle={this.handleMobileMenuToggle}
        menuItems={menuItems}
        notificationCollection={notificationCollection}
        user={user}
      />
    );
  }
}
