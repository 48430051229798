import * as React from 'react';

import Checkbox   from '@material-ui/core/Checkbox';
import TableCell  from '@material-ui/core/TableCell';
import TableRow   from '@material-ui/core/TableRow';

import Text from '@styles/Text';

import { PlaylistTrackInterface } from '@components/Playlist/PlaylistTrackInterface';

interface TracksTableRowProps {
  index: number;
  onClick: (id) => void;
  row: PlaylistTrackInterface;
  selected: Array<string>;
  publicView: boolean;
}

export default function TracksTableRow({
  index,
  onClick,
  row,
  selected,
  publicView
}: TracksTableRowProps): JSX.Element {
  const isSelected      = (id) => selected.indexOf(id) !== -1;
  const isItemSelected  = isSelected(row.id);
  const labelId         = `tracks-table-${index}`;

  return (
    <TableRow
      hover
      onClick={() => onClick(row.id)}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      selected={isItemSelected}
    >
      {!publicView && (
        <TableCell padding="checkbox">
          <Checkbox
            checked={isItemSelected}
            inputProps={{ 'aria-labelledby': labelId }}
          />
        </TableCell>
      )}

      <TableCell component="th" id={labelId} scope="row">
        <Text type="label">
          {row.artist}
        </Text>
      </TableCell>
      <TableCell component="th" scope="row">
        <Text type="label">
          {row.name}
        </Text>
      </TableCell>
      <TableCell component="th" scope="row">
        <Text type="label">
          {row.albumName}
        </Text>
      </TableCell>
      <TableCell align="right">
        <Text type="label">
          {row.durationMs}
        </Text>
      </TableCell>
      <TableCell component="th" scope="row">
        <Text type="label">
          {row.addedAt}
        </Text>
      </TableCell>
    </TableRow>
  );
}
