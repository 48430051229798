import * as React from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import LockIcon from '@material-ui/icons/Lock';
import LinkIcon from '@material-ui/icons/Link';
import PublicIcon from '@material-ui/icons/Public';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import ContentActionDialog  from '@styles/dialogs/ContentActionDialog';
import Text                 from '@styles/Text';
import DefaultTheme         from '@themes/DefaultTheme';

import { PlaylistInterface } from '@components/Playlist/PlaylistInterface';
import { ShareTypes } from '@components/Playlist/SharePlaylistMenu/ShareTypes';

interface SharePlaylistMenuViewProps {
  onClick: () => void;
  onClose: () => void;
  onChangeShareType: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSave: () => void;
  fromPlaylistPage: boolean;
  selectedIds: Array<PlaylistInterface['id']>;
  shareType: PlaylistInterface['shareType']
  showDialog: boolean;
}

const useStyles = makeStyles(({ breakpoints, spacing }: DefaultTheme) => createStyles({
  root: {
    [breakpoints.up('sm')]: {
      alignItems: 'flex-end'
    }
  },
  formControl: {
    marginLeft: spacing(4),
    marginBottom: spacing(2)
  }
}));

export default function SharePlaylistMenuView({
  onClick,
  onClose,
  onChangeShareType,
  onSave,
  fromPlaylistPage,
  selectedIds,
  shareType,
  showDialog
}: SharePlaylistMenuViewProps): JSX.Element {
  const classes = useStyles();

  let buttonIcon = <LockIcon />;
  let title = 'Share Playlist';

  const playlistLink = selectedIds.length === 1 ? `https://playlists.cloud/shared/playlists/${selectedIds[0]}` : null;
  if (selectedIds.length > 1) {
    title += 's';
  }

  switch (shareType) {
    case ShareTypes.Link:
      buttonIcon = <LinkIcon />;
      break;
    case ShareTypes.Public:
      buttonIcon = <PublicIcon />;
      break;
  }

  const dialogContent = (
    <Grid container>
      <Grid item>
        <Text type="label">
          Your playlists are private by default. To share them, you have two options:
        </Text>
        <Text type="label">
          <ul>
            <li>
              Link-only access: Your playlist won’t appear in our public directory, but anyone with its link can view it.
            </li>
            <li>
              Public: Your playlist will be visible in our public directory.
            </li>
          </ul>
        </Text>
      </Grid>
      <Grid item xs={11}>
        <FormControl fullWidth component="fieldset" className={classes.formControl}>
          <RadioGroup aria-label="share_type" name="share_type" value={shareType} onChange={onChangeShareType}>
            <FormControlLabel value={ShareTypes.Private} control={<Radio />} label="Private" />
            <FormControlLabel value={ShareTypes.Link} control={<Radio />} label="Link-only access" />

            {selectedIds.length === 1 && (
              <TextField
                fullWidth
                inputProps={{ readOnly: true }}
                disabled={shareType !== ShareTypes.Link}
                value={playlistLink}
                variant="outlined"
              />
            )}

            <FormControlLabel value={ShareTypes.Public} control={<Radio />} label="Public" />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item>
        <Text type="label">
          Other users will be able to add your shared playlist(s) to their collection and/or export as static files.
          <br />
          <Link href="/help">
            Learn more
          </Link>
          {' '}
          about shared playlists.
        </Text>
      </Grid>
    </Grid>
  );

  const dialogAction = (
    <Button
      autoFocus
      onClick={onSave}
    >
      Save changes
    </Button>
  );

  const shareDialog = (
    <ContentActionDialog
      onClose={onClose}
      open={showDialog}
      title={title}
      content={dialogContent}
      action={dialogAction}
    />
  );

  return fromPlaylistPage ? (
    <Grid container direction="column" className={classes.root}>
      <Grid item xs={12}>
        {shareDialog}

        <Button
          onClick={onClick}
          size="large"
          startIcon={buttonIcon}
          variant="outlined"
        >
          Share
        </Button>
      </Grid>
    </Grid>
  ) : shareDialog;
}
