import * as React from 'react';

import Box        from '@material-ui/core/Box';
import Grid       from '@material-ui/core/Grid';
import Pagination from '@material-ui/lab/Pagination';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import CardsView                        from '@components/ManagePlaylists/ViewModes/CardsView';
import ListView                         from '@components/ManagePlaylists/ViewModes/ListView';
import SharedActionBar                  from '@components/Shared/SharedActionBar';
import { PlaylistInterface }            from '@components/Playlist/PlaylistInterface';
import { UserInterface }                from '@components/User/UserInterface';
import type { PlaylistSortOptionsType } from '@components/ManagePlaylists/PlaylistSortOptionsType';

import Text         from '@styles/Text';
import DefaultTheme from '@themes/DefaultTheme';

interface SharedHomeViewProps {
  loading: boolean;
  onPageChange: (event: React.ChangeEvent<unknown>, page: number) => void;
  onSearchTermChange: (term: string) => void;
  onSearchTermSubmit: () => void;
  onSelectSort: (sortingBy: PlaylistSortOptionsType) => void;
  onViewModeChange: (viewMode: string) => void;
  pages: number;
  playlists: Array<PlaylistInterface>;
  playlistsCount: number;
  searchTerm: string;
  sortingBy: PlaylistSortOptionsType;
  userId: null | UserInterface['id'];
  viewMode: string;
}

const useStyles = makeStyles(({ breakpoints, colors, spacing }: DefaultTheme) => createStyles({
  root: {
    flexGrow: 1
  },
  bottomSpacing: {
    [breakpoints.down('sm')]: {
      marginBottom: spacing(3)
    },
    [breakpoints.up('sm')]: {
      marginBottom: spacing(5)
    }
  },
  paginationBox: {
    margin: `${spacing(3)}px auto ${spacing(5)}px auto`
  },
  subtitle: {
    color: colors.grey
  }
}));

export default function SharedHomeView({
  loading,
  onPageChange,
  onSelectSort,
  onSearchTermChange,
  onSearchTermSubmit,
  onViewModeChange,
  pages,
  playlists,
  playlistsCount,
  searchTerm,
  sortingBy,
  userId,
  viewMode
}: SharedHomeViewProps): JSX.Element {
  const classes = useStyles();

  let view;

  if (viewMode === 'cards') {
    view = (
      <CardsView
        inEditMode={false}
        loading={loading}
        onEditSelectItem={() => {}}
        playlists={playlists}
        selectedPlaylistIds={[]}
        userId={userId}
        publicView
      />
    );
  } else {
    view = (
      <ListView
        inEditMode={false}
        loading={loading}
        onEditSelectItem={() => {}}
        playlists={playlists}
        selectedPlaylistIds={[]}
        userId={userId}
        publicView
      />
    );
  }

  return (
    <>
      <Grid item xs={12} className={classes.bottomSpacing}>
        <Text type="heading" className={classes.bottomSpacing}>
          Shared Playlists
        </Text>

        <Text type="displayXs" className={classes.subtitle}>
          Discover playlists shared by our users.
        </Text>
      </Grid>
      <Grid item xs={12}>
        <SharedActionBar
          onSelectSort={onSelectSort}
          onSearchTermChange={onSearchTermChange}
          onSearchTermSubmit={onSearchTermSubmit}
          onViewModeChange={onViewModeChange}
          playlistsCount={playlistsCount}
          searchTerm={searchTerm}
          sortingBy={sortingBy}
          viewMode={viewMode}
        />

        <div className={classes.root}>
          <Grid container spacing={3}>
            {view}
          </Grid>
        </div>
      </Grid>

      {pages > 1 && (
        <Box className={classes.paginationBox}>
          <Pagination
            count={pages}
            boundaryCount={2}
            onChange={onPageChange}
            siblingCount={0}
            showFirstButton
            showLastButton
          />
        </Box>
      )}
    </>
  );
}
