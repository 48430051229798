import * as React from 'react';

import Box            from '@material-ui/core/Box';
import Breadcrumbs    from '@material-ui/core/Breadcrumbs';
import Button         from '@material-ui/core/Button';
import Grid           from '@material-ui/core/Grid';
import Hidden         from '@material-ui/core/Hidden';
import Link           from '@material-ui/core/Link';
import EditIcon       from '@material-ui/icons/Edit';
import useMediaQuery  from '@material-ui/core/useMediaQuery';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import OrphanTooltip              from '@components/Playlist/common/OrphanTooltip';
import PlaylistMenu               from '@components/Playlist/PlaylistMenu/PlaylistMenu';
import PublicPlaylistMenu         from '@components/Playlist/PlaylistMenu/PublicPlaylistMenu';
import SharePlaylistMenu          from '@components/Playlist/SharePlaylistMenu/SharePlaylistMenu';
import ProviderLogo               from '@components/Providers/common/ProviderLogo';
import SearchResource             from '@components/common/SearchResource';
import EditPlaylist               from '@components/Playlist/EditPlaylist/EditPlaylist';
import TracksTable                from '@components/Playlist/tracks/TracksTable';
import HtmlEmbed                  from '@components/Playlist/PlayerEmbed/HtmlEmbed';
import { PlaylistInterface }      from '@components/Playlist/PlaylistInterface';
import { PlaylistTrackInterface } from '@components/Playlist/PlaylistTrackInterface';
import { UserInterface }          from '@components/User/UserInterface';
import { InternalProvidersEnum }  from '@components/Providers/InternalProvidersEnum';

import DefaultTheme from '@themes/DefaultTheme';
import Text         from '@styles/Text';

// @ts-ignore
import DefaultPlaylistThumb  from 'images/default_playlist_thumb.svg';

interface PlaylistHomeViewProps {
  loading: boolean;
  order: string;
  page: number;
  searchTerm: string;
  sortBy: string;
  tracks: Array<PlaylistTrackInterface>;
  total: number;
  playlist: PlaylistInterface;
  onSearchTermChange: (term: string) => void;
  onSearchTermSubmit: () => void;
  onSortOrderChange: (sortBy: string, order: string) => void;
  onPageChange: (page: number, perPage: number) => void;
  publicView: boolean;
  userId: null | UserInterface['id'];
}

const useStyles = makeStyles(({ breakpoints, spacing }: DefaultTheme) => createStyles({
  tableGridItem: {
    marginBottom: spacing(5)
  },
  descriptionGridItem: {
    marginBottom: spacing(3)
  },
  editButton: {
    marginBottom: spacing(5)
  },
  headingGrid: {
    marginBottom: spacing(5)
  },
  headerBox: {
    marginBottom: spacing(5)
  },
  headingPlaylistName: {
    overflow: 'hidden'
  },
  rightAlignedGridItem: {
    [breakpoints.up('sm')]: {
      textAlign: 'right'
    }
  },
  importedText: {
    marginBottom: spacing(3)
  },
  playlistImage: {
    width: '100%'
  },
  playlistMenuGridItem: {
    textAlign: 'right'
  },
  providerPlaylistLink: {
    textTransform: 'capitalize'
  },
  sectionTitle: {
    marginTop: spacing(2),
    marginBottom: spacing(2)
  }
}));

export default function PlaylistHomeView(props: PlaylistHomeViewProps): JSX.Element {
  const classes = useStyles();
  const isMobileResolution = useMediaQuery('(max-width:767px)');

  const {
    loading,
    order,
    onSearchTermChange,
    onSearchTermSubmit,
    onSortOrderChange,
    onPageChange,
    page,
    searchTerm,
    sortBy,
    total,
    tracks,
    playlist,
    publicView,
    userId
  } = props;

  const [showEditPlaylistDialog, setShowEditPlaylistDialog] = React.useState(false);

  const playlistImage = playlist.images.length > 0 ? playlist.images[0].url : DefaultPlaylistThumb;

  let disconnectedLabel = '';
  let disconnectedTooltip = null;

  if (playlist.orphan && !publicView) {
    disconnectedLabel = '(Disconnected)';
    disconnectedTooltip = (<OrphanTooltip />);
  }

  let importedFromBlock;

  if (playlist.providerSource === 'upload') {
    const importText = 'Imported from a CSV file.';

    importedFromBlock = (
      <>
        <Text type="label" color="secondary" className={classes.importedText}>
          {publicView && (<>{importText}</>)}
          {!publicView && (
            <Link href={`/imports/${playlist.importSummaryId}/summary`}>
              {importText}
            </Link>
          )}
        </Text>
      </>
    );
  } else if (playlist.providerSource !== 'shared') {
    importedFromBlock = (
      <>
        <Text type="label" color="secondary" className={classes.importedText}>
          {`Imported from ${disconnectedLabel}`}
          {disconnectedTooltip}
        </Text>

        <ProviderLogo providerSource={playlist.providerSource} height={40} />

        {playlist.providerPlaylistLink && (
          <Text type="label" className={classes.providerPlaylistLink}>
            <Link href={playlist.providerPlaylistLink} target="_blank" referrerPolicy="no-referrer">
              {`Open in ${playlist.providerSource}`}
            </Link>
          </Text>
        )}
      </>
    );
  }

  const breadCrumbPlaylistsLabel = publicView ? 'Shared Playlists' : 'Playlists';
  const breadCrumbPlaylistsLink = publicView ? '/shared' : '/playlists';

  const playlistMenu = publicView ? (
    <PublicPlaylistMenu
      playlist={playlist}
      userId={userId}
      variant="Button"
    />
  ) : (
    <PlaylistMenu
      playlist={playlist}
      variant="Button"
    />
  );

  const editPlaylistButton = (
    <Button
      className={classes.editButton}
      onClick={() => setShowEditPlaylistDialog(true)}
      startIcon={<EditIcon />}
      size="large"
      variant="outlined"
    >
      Edit
    </Button>
  );

  return (
    <>
      <Grid item xs={12} className={classes.headingGrid}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" href={breadCrumbPlaylistsLink}>
                <Text type="label">
                  {breadCrumbPlaylistsLabel}
                </Text>
              </Link>

              <Text type="label" color="secondary">
                {playlist.name}
              </Text>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={4} md={2}>
            <img className={classes.playlistImage} src={playlistImage} alt={playlist.name} />
          </Grid>
          <Grid item xs={8} md={10}>
            <Grid container alignItems="flex-start">
              <Grid item xs={10}>
                <Box className={classes.headerBox}>
                  <Text type="heading" className={classes.headingPlaylistName}>
                    {playlist.name}
                  </Text>

                  <Text type="displaySm" color="secondary" weight={500}>
                    {playlist.trackCount}
                    {` track${playlist.trackCount > 1 ? 's' : ''}`}
                  </Text>

                  {playlist.description && (
                    <Grid item xs={10} className={classes.descriptionGridItem}>
                      <Text type="displayXs">
                        {playlist.description}
                      </Text>
                    </Grid>
                  )}

                  <Text type="label" color="lightGrey">
                    {publicView ? `Shared by ${playlist.user.username}.` : ''}
                    {publicView && <br />}
                    {`Last updated ${playlist.lastSyncedAt}. Imported on ${playlist.createdAt}.`}
                  </Text>
                </Box>
              </Grid>
              {!isMobileResolution && (
                <Grid item xs={12} md={2} className={classes.rightAlignedGridItem}>
                  {!publicView && editPlaylistButton}
                  {importedFromBlock}
                </Grid>
              )}
            </Grid>
          </Grid>

          {isMobileResolution && (
            <Grid item xs={12} md={2} className={classes.rightAlignedGridItem}>
              {importedFromBlock}
            </Grid>
          )}
        </Grid>
      </Grid>

      <Hidden smUp>
        <Grid container justifyContent="flex-start" spacing={2}>
          {!publicView && (
            <Grid item>
              {editPlaylistButton}
            </Grid>
          )}

          {!publicView && (
            <Grid item className={classes.playlistMenuGridItem}>
              <SharePlaylistMenu fromPlaylistPage activeShareType={playlist.shareType} selectedIds={[playlist.id]} />
            </Grid>
          )}

          <Grid item className={classes.playlistMenuGridItem}>
            {playlistMenu}
          </Grid>
        </Grid>
      </Hidden>

      {/*{publicView && !(playlist.providerSource in InternalProvidersEnum) && (*/}
      {/*  <HtmlEmbed playlist={playlist} />*/}
      {/*)}*/}

      <Grid item xs={12}>
        <Grid container alignItems="center" justifyContent="space-between" spacing={3} wrap="wrap">
          <Grid item>
            <Text type="displaySm" className={classes.sectionTitle}>
              Tracks
            </Text>
          </Grid>
          <Grid item xs={9} sm={5}>
            <SearchResource
              onSearchTermChange={onSearchTermChange}
              onSearchTermSubmit={onSearchTermSubmit}
              placeholder="Filter by term"
              searchTerm={searchTerm}
            />
          </Grid>
          <Hidden xsDown>
            <Grid item container xs={6} justifyContent="flex-end" spacing={3}>
              {!publicView && (
                <Grid item>
                  <SharePlaylistMenu fromPlaylistPage activeShareType={playlist.shareType} selectedIds={[playlist.id]} />
                </Grid>
              )}

              <Grid item>
                {playlistMenu}
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </Grid>

      {showEditPlaylistDialog && (
        <EditPlaylist
          playlist={playlist}
          onCloseEditPlaylistDialog={() => setShowEditPlaylistDialog(false)}
        />
      )}

      <Grid item xs={12} className={classes.tableGridItem}>
        <Grid container spacing={3}>
          <TracksTable
            loading={loading}
            onSortOrderChange={onSortOrderChange}
            onPageChange={onPageChange}
            order={order}
            page={page}
            playlistId={playlist.id}
            rows={tracks}
            sortBy={sortBy}
            total={total}
            publicView={publicView}
          />
        </Grid>
      </Grid>
    </>
  );
}
