import * as React from 'react';

import MenuVariantView    from '@components/Playlist/PlaylistMenu/MenuVariantView';
import ButtonVariantView  from '@components/Playlist/PlaylistMenu/ButtonVariantView';

import { PlaylistMenuItemInterface }  from '@components/Playlist/common/PlaylistMenuItemInterface';

interface PlaylistMenuViewProps {
  anchorEl?: Element | null;
  menuItems: Array<PlaylistMenuItemInterface>;
  onClose?: () => void;
  variant: string;
}

export default function PlaylistMenuView({
  anchorEl,
  onClose,
  menuItems,
  variant
}: PlaylistMenuViewProps): JSX.Element {
  const menu = variant === 'Menu'
    ? (
      <MenuVariantView
        anchorEl={anchorEl}
        items={menuItems}
        onClose={onClose}
      />
    ) : (
      <ButtonVariantView
        items={menuItems}
      />
    )
  ;

  return menu;
}
