import * as React from 'react';

import Divider  from '@material-ui/core/Divider';
import Menu     from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { PlaylistMenuItemInterface } from '@components/Playlist/common/PlaylistMenuItemInterface';

interface MenuVariantViewProps {
  anchorEl: Element,
  items: Array<PlaylistMenuItemInterface>,
  onClose: () => void
}

export default function MenuVariantView({ anchorEl, items, onClose }: MenuVariantViewProps): JSX.Element {
  return (
    <Menu
      id="playlist-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      keepMounted
    >
      {items.map((item, idx) => (item.title === 'divider' ? (
        <Divider key={`d-${item.title}-${idx}`} />
      ) : (
        <MenuItem
          key={item.title}
          onClick={item.onClick}
        >
          {item.title}
        </MenuItem>
      )))}
    </Menu>
  );
}
