import * as React from 'react';

import PublicPlaylistMenuView from '@components/Playlist/PlaylistMenu/PublicPlaylistMenuView';
import ExportToProvider       from '@components/ExportPlaylists/ExportToProvider';
import SharedPlaylistApi      from '@api/SharedPlaylistApi';

import FlashContext                   from '@contexts/FlashContext';
import { ExportTypes }                from '@components/ExportPlaylists/ExportTypes';
import { PlaylistInterface }          from '@components/Playlist/PlaylistInterface';
import { UserInterface }              from '@components/User/UserInterface';
import { PlaylistMenuItemInterface }  from '@components/Playlist/common/PlaylistMenuItemInterface';

enum PlaylistMenuVariants {
  Menu = 'menu',
  Button = 'button'
}

type PlaylistMenuVariantType = keyof typeof PlaylistMenuVariants;

interface PublicPlaylistMenuState {
  showExportToProviderDialog: boolean;
}

interface PublicPlaylistMenuProps {
  anchorEl?: Element | null;
  onClose?: () => void;
  playlist: PlaylistInterface;
  userId: null | UserInterface['id'];
  variant: PlaylistMenuVariantType;
}

export default class PublicPlaylistMenu extends React.Component<PublicPlaylistMenuProps, PublicPlaylistMenuState> {
  static contextType = FlashContext;

  static defaultProps = {
    anchorEl: null,
    onClose: (): void => {}
  }

  constructor(props: PublicPlaylistMenuProps) {
    super(props);

    this.state = {
      showExportToProviderDialog: false
    };
  }

  redirectUserToLogin = (): void => {
    const { playlist } = this.props;
    window.location.href = `/users/login?redirect_path=/shared/playlists/${playlist.id}`;
  }

  handleOnClonePlaylist = (): void => {
    const { playlist } = this.props;
    SharedPlaylistApi.clonePlaylist(playlist.id, this.context);
  }

  handleOnExportToProviderClick = (): void => {
    this.setState({ showExportToProviderDialog: true }, () => {
      this.props.onClose();
    });
  }

  handleOnCloseExportDialog = (): void => this.setState({ showExportToProviderDialog: false });

  handleOnExportAsFileClick = (exportType: ExportTypes): void => {
    const {
      onClose,
      playlist
    } = this.props;

    let exportFormat = '';

    if (exportType === ExportTypes.Csv) {
      exportFormat = 'csv';
    } else if (exportType === ExportTypes.Json) {
      exportFormat = 'json';
    }

    SharedPlaylistApi.requestExport(
      playlist.id,
      'file',
      exportFormat,
      '',
      '',
      this.context
    );

    onClose();
  }

  render(): JSX.Element {
    const {
      showExportToProviderDialog
    } = this.state;

    const {
      anchorEl,
      onClose,
      playlist,
      userId,
      variant
    } = this.props;

    const dividerMenuItem = {
      title: 'divider',
      onClick: () => {}
    };

    const menuItems: Array<PlaylistMenuItemInterface> = [
      {
        title: 'Add to my playlists',
        onClick: userId ? this.handleOnClonePlaylist : this.redirectUserToLogin
      },
      dividerMenuItem,
      {
        title: 'Export to Provider',
        onClick: userId ? this.handleOnExportToProviderClick : this.redirectUserToLogin
      },
      {
        title: 'Export as CSV',
        onClick: () => (userId ? this.handleOnExportAsFileClick(ExportTypes.Csv) : this.redirectUserToLogin())
      },
      {
        title: 'Export as JSON',
        onClick: () => (userId ? this.handleOnExportAsFileClick(ExportTypes.Json) : this.redirectUserToLogin())
      }
    ];

    const fromSourceInitialValue = 'orphan';

    return (
      <>
        <PublicPlaylistMenuView
          anchorEl={anchorEl}
          onClose={onClose}
          menuItems={menuItems}
          variant={variant}
        />
        {showExportToProviderDialog && userId && (
          <ExportToProvider
            fromSourceInitialValue={fromSourceInitialValue}
            onCloseExportDialog={this.handleOnCloseExportDialog}
            playlistIds={[playlist.id]}
            publicView
          />
        )}
      </>
    );
  }
}
