import { ShareTypes }     from '@components/Playlist/SharePlaylistMenu/ShareTypes';
import { UserInterface }  from '@components/User/UserInterface';

interface PlaylistImageInterface {
  height: number;
  width: number;
  url: string;
}

interface PlaylistUserInterface {
  username: UserInterface['username']
}

export interface PlaylistInterface {
  id: string;
  name: string;
  description?: string;
  createdAt: string;
  importSummaryId?: string;
  lastSyncedAt: string;
  orphan: boolean;
  providerSource: string;
  providerPlaylistLink: string;
  shareType: ShareTypes;
  sourceId: string;
  sourceKind: number;
  trackCount: number;
  user?: PlaylistUserInterface;
  images: Array<PlaylistImageInterface>;
}

export const orphanPlaylistSourceKey = 'orphan';
export const orphanPlaylistSourceOption = {
  label: 'Orphan Playlists',
  value: orphanPlaylistSourceKey
};
