import * as React from 'react';

import Grid                   from '@material-ui/core/Grid';
import Button                 from '@material-ui/core/Button';
import MenuItem               from '@material-ui/core/MenuItem';
import KeyboardArrowDownIcon  from '@material-ui/icons/KeyboardArrowDown';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import { PlaylistSortOptions }          from '@components/ManagePlaylists/PlaylistSortOptions';
import type { PlaylistSortOptionsType } from '@components/ManagePlaylists/PlaylistSortOptionsType';
import UploadPlaylistsButton            from '@components/ManagePlaylists/common/UploadPlaylistsButton';
import ToggleView                       from '@components/ManagePlaylists/ViewModes/ToggleView';
import SearchResource                   from '@components/common/SearchResource';

import DefaultTheme from '@themes/DefaultTheme';
import MenuBaseline from '@styles/MenuBaseline';
import Text         from '@styles/Text';

interface ActionBarProps {
  onSearchTermChange: (term: string) => void;
  onSearchTermSubmit: () => void;
  onSelectSort: (sortingBy: PlaylistSortOptionsType) => void;
  onViewModeChange: (viewMode: string) => void;
  playlistsCount: number;
  searchTerm: string;
  sortingBy: PlaylistSortOptionsType;
  viewMode: string;
}

const useStyles = makeStyles(({ breakpoints, spacing }: DefaultTheme) => createStyles({
  alignRight: {
    textAlign: 'right'
  },
  playlistsCountText: {
    [breakpoints.down('xs')]: {
      marginTop: spacing(1)
    }
  },
  root: {
    marginBottom: spacing(2)
  },
  searchBarGridItem: {
    '&.MuiGrid-item': {
      [breakpoints.down('sm')]: {
        paddingTop: 0
      }
    }
  },
  sortContainer: {
    [breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
      textAlign: 'right'
    }
  },
  rightAlignedGridItem: {
    textAlign: 'right',
    [breakpoints.up('sm')]: {
      marginLeft: spacing(3)
    }
  },
  uploadPlaylistsGridItem: {
    '&.MuiGrid-item': {
      paddingLeft: 0
    }
  }
}));

export default function SharedActionBar({
  onSearchTermChange,
  onSearchTermSubmit,
  onSelectSort,
  onViewModeChange,
  playlistsCount,
  searchTerm,
  sortingBy,
  viewMode
}: ActionBarProps): JSX.Element {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleSortClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSortMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (sortBy: PlaylistSortOptionsType) => {
    onSelectSort(sortBy);
    handleSortMenuClose();
  };

  const menuItems = Object.entries(PlaylistSortOptions).map((sortOption) => {
    const [key, option] = sortOption;

    return (
      <MenuItem key={key} onClick={() => handleMenuItemClick(key)}>
        {option.label}
      </MenuItem>
    );
  });

  return (
    <>
      <Grid container className={classes.root} spacing={3}>
        <Grid item xs={12} sm="auto">
          <Text type="displayXs" className={classes.playlistsCountText}>
            <b>{playlistsCount}</b>
            {` playlist${playlistsCount > 1 ? 's' : ''}`}
          </Text>
        </Grid>
        {/*<Grid item>*/}
        {/*  -export playlists button-*/}
        {/*</Grid>*/}
        <Grid item className={classes.uploadPlaylistsGridItem}>
          <UploadPlaylistsButton />
        </Grid>
      </Grid>

      <Grid container className={classes.root} spacing={4}>
        <Grid item xs={12} md={6} className={classes.searchBarGridItem}>
          <SearchResource
            onSearchTermChange={onSearchTermChange}
            onSearchTermSubmit={onSearchTermSubmit}
            placeholder="Search by name"
            searchTerm={searchTerm}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container className={classes.sortContainer}>
            <Grid item xs={6}>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleSortClick}
                endIcon={<KeyboardArrowDownIcon />}
              >
                {PlaylistSortOptions[sortingBy].label}
              </Button>
              <MenuBaseline
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleSortMenuClose}
              >
                {menuItems}
              </MenuBaseline>
            </Grid>

            <Grid item xs={6} sm="auto" className={classes.rightAlignedGridItem}>
              <ToggleView
                viewMode={viewMode}
                onViewModeChange={onViewModeChange}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
