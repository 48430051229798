import request from 'superagent';

import {
  BASE_URL,
  genericApiResponse
} from '@api/BaseApi';

const ENDPOINT = `${BASE_URL}/user_settings`;

export default class UserSettingsApi {
  static updateSettings(
    defaultPlaylistShareType: number
  ): Promise<{ body: genericApiResponse }> {
    return request
      .post(`${ENDPOINT}/quick_settings`)
      .send({
        default_playlist_share_type: defaultPlaylistShareType
      })
    ;
  }
}
