import * as React from 'react';

import Button         from '@material-ui/core/Button';
import Fade           from '@material-ui/core/Fade';
import Grid           from '@material-ui/core/Grid';
import TextField      from '@material-ui/core/TextField';
import FormControl    from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';

import { EditPlaylistErrorType } from '@components/Playlist/EditPlaylist/EditPlaylistErrorType';
import LoadingIndicator from '@components/common/LoadingIndicator';

import ContentActionDialog  from '@styles/dialogs/ContentActionDialog';
import FormHelperText       from '@styles/form/FormHelperText';
import DefaultTheme         from '@themes/DefaultTheme';

interface EditPlaylistViewProps {
  name: string;
  description: string;
  errors: EditPlaylistErrorType;
  saving: boolean;
  onChangeName: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeDescription: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onCloseEditPlaylistDialog: () => void;
  onSave: (event: React.SyntheticEvent) => void;
}

const useStyles = makeStyles(({ spacing }: DefaultTheme) => ({
  formControl: {
    marginBottom: spacing(1),
    width: '100%',
    '& .MuiInputLabel-shrink': {
      marginTop: 0
    }
  },
  inputLabel: {
    marginTop: spacing(2)
  }
}));

export default function EditPlaylistView({
  name,
  description,
  errors,
  saving,
  onChangeName,
  onChangeDescription,
  onCloseEditPlaylistDialog,
  onSave
}: EditPlaylistViewProps): JSX.Element {
  const classes = useStyles();

  const content = (
    <form onSubmit={onSave}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl
            error={errors.name.length > 0}
            className={classes.formControl}
            fullWidth
          >
            <TextField
              onChange={onChangeName}
              error={errors.name.length > 0}
              name="name"
              label="Name"
              value={name}
              variant="outlined"
              type="text"
            />

            {errors.name.length > 0 && (
              <Fade in>
                <FormHelperText>
                  {errors.name}
                </FormHelperText>
              </Fade>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            error={errors.description.length > 0}
            className={classes.formControl}
            fullWidth
          >
            <TextField
              onChange={onChangeDescription}
              error={errors.description.length > 0}
              name="description"
              label="Description"
              minRows={3}
              value={description}
              variant="outlined"
              type="text"
              multiline
            />

            {errors.description.length > 0 && (
              <Fade in>
                <FormHelperText>
                  {errors.description}
                </FormHelperText>
              </Fade>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </form>
  );

  const action = (
    <Button
      autoFocus
      onClick={onSave}
      disabled={saving}
    >
      {saving && <LoadingIndicator />}
      Save Changes
    </Button>
  );

  return (
    <ContentActionDialog
      onClose={onCloseEditPlaylistDialog}
      title="Edit Playlist"
      content={content}
      action={action}
      open
    />
  );
}
